import  React, {useState, useEffect} from "react";
import {
    styled,
    alpha,
    AppBar,
    Box,
    Toolbar,
    IconButton,
    InputBase,
    Grid,
    MenuItem,
    Menu,
    Select,
    Typography,
    Divider,
    FormControlLabel,
    FormGroup,
    Switch,
    Tooltip,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import MailIcon from "@mui/icons-material/Mail";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreIcon from "@mui/icons-material/MoreVert";
import LanguageIcon from "@mui/icons-material/Language";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../../Auth/service/AuthService";
import SideNavigation from "../SideNavigation";
import { Language } from "../../../i18n";
import { ROUTES } from "../../../constants/routes";
import { useAppSelector } from "../../../redux/hooks";
import CONSTANTS from "../../../constants/constants";
import NotificationMessage from "../Notifcation";



export default function PrimarySearchAppBar(props: any) {

    const name = useAppSelector((state) => state.common.loggedInUserName) ?? "";

    const userType = useAppSelector((state) => state.common.loggedInUserType) ?? null;

    const userStatus = useAppSelector((state) => state.common.isMaintenance ) ?? "false";
    const { logout ,setIsMsgRequired, isMsgRequired, msgVisibility, setMsgVisibility, isCookiePresent } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [settingsAnchorE1, setSettingsAnchorE1] =
    React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);   

    const isMenuOpen = Boolean(anchorEl);
    const isSettingsMenuOpen = Boolean(settingsAnchorE1);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [lang, setLang] = React.useState<string | HTMLElement>(i18n.language);
    // useEffect(()=>{
    //     setTimeout(()=>{
    //         setIsMsgRequired(localStorage.getItem("systemUpdateNotification"));
    //     },5000);
    //     setIsMsgRequired(localStorage.getItem("systemUpdateNotification"));      
    // },[window.location.pathname,localStorage.getItem("systemUpdateNotification")]);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSettingsAnchorE1(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const navigateEditProfile = () => { 
        navigate(ROUTES.ADMIN_USER_DETAILS);
        handleSettingsMenuClose();
    };
    const navigateSystemConfiguration = () => { 
        navigate(ROUTES.CRON_JOB);
        handleSettingsMenuClose();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleSettingsMenuClose = () => {
        setSettingsAnchorE1(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        handleMenuClose();
        new AuthService().logout();
        logout();
    };

    const menuId = "primary-search-account-menu";
 
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };


    // -----------Profile Menu------
    const renderMenu = (
        <Menu
            className="menu_margin"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handlepProfile}>{t("header.profile")}</MenuItem> */}
            {/* <MenuItem onClick={handleMyAccount}>{t("header.my_account")}</MenuItem> */}
            <Box className="menu_width">
                <Box className="name_menu">
                    <Typography variant="h6" className="menu_item display_block">
                        {name}
                    </Typography>
                </Box>

                <Divider />
                <MenuItem onClick={handleLogout}>{t("header.log_out")}</MenuItem>
            </Box>
        </Menu>
    );

    const settingMenuID = "settings-menu";
    const renderSettingsMenu = (
        <Menu
            className="menu_margin"
            anchorEl={settingsAnchorE1}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={settingMenuID}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isSettingsMenuOpen}
            onClose={handleSettingsMenuClose}
        >
            {/* <MenuItem onClick={handleUpdatePassword}>
                {t("header.update_password")}
            </MenuItem> */}
            <MenuItem onClick={navigateEditProfile}>{t("header.edit_profile")}</MenuItem>
            {/* <MenuItem onClick={navigateSystemConfiguration}>{t("system_configuration.system_configuration")}</MenuItem> */}
        </Menu>
    );

    useEffect(()=>{
        setIsMsgRequired(localStorage.getItem("systemUpdateNotification")); 
        setMsgVisibility(localStorage.getItem("systemUpdateNotificationVisibility"));

        const intervalId = setInterval(() => {
            setIsMsgRequired(localStorage.getItem("systemUpdateNotification"));   
            setMsgVisibility(localStorage.getItem("systemUpdateNotificationVisibility"));
        }, 1000);

        setTimeout(()=>{
            clearInterval(intervalId);        
        },10000);

        return () => clearInterval(intervalId);
        
    },[window.location.pathname, localStorage.getItem("systemUpdateNotification"), localStorage.getItem("systemUpdateNotificationVisibility")]);

    // -----------Login Menu---------
    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >


            <MenuItem onClick={handleSettingsMenuOpen}>
                <IconButton size="large" color="inherit">
                    <SettingsIcon />
                </IconButton>
                <p>{t("header.settings")}</p>
            </MenuItem>


            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>{t("header.profile")}</p>
            </MenuItem>
            
            {/* <MenuItem>
                <IconButton size="large" color="inherit">
                    <QuestionMarkIcon />
                </IconButton>
                <p>{t("header.help")}</p>
            </MenuItem> */}
            <MenuItem>
                <IconButton
                    size="large"
                    aria-haspopup="true"
                    color="inherit"
                    sx={{ mr: -1.5 }}
                >
                    <LanguageIcon />
                </IconButton>
                <Select
                    sx={{
                        "& > fieldset": { border: "none" },
                        color: "black",
                        "& .MuiSvgIcon-root": {
                            color: "black",
                        },
                    }}
                    value={lang}
                    onChange={(e) => handleLanguageChange(e.target.value as string)}
                >
                    {Language.map((item, mobileMenuIndex) => {
                        return (
                            <MenuItem
                                selected={item.value == lang ? true : false}
                                value={item.value}
                                key={mobileMenuIndex}
                            >
                                {item.key}
                            </MenuItem>
                        );
                    })}
                </Select>
            </MenuItem>
        </Menu>
    );

    const handleLanguageChange = (lang: string) => {
        setLang(lang);
        i18n.changeLanguage(lang);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };



    return (
    // -----AppBar Start Here---------
        <Box className={isMsgRequired ? "header-margin-bottom" : ""}>
            <AppBar position="static">
                <Toolbar sx={{ backgroundColor: "#00684D" }}>
                    {/* ------hamburger menu------ */}
                    {
                        (userType != CONSTANTS.USER_TYPE_ADMIN && userStatus == "true") ||
                        (userType == CONSTANTS.USER_TYPE_SUPER_ADMIN && userStatus == "true" ||userStatus == "false" ) ||
                        (userType == CONSTANTS.USER_TYPE_ADMIN && userStatus == "false") ? (  <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }} >

                                <SideNavigation /> 
                           
                            </IconButton>) : null
                    }

                    {/* ------Logo button------- */}
                    <Link to={ userType == CONSTANTS.USER_TYPE_SUPER_ADMIN && userStatus == "true" ||userStatus == "false" ?  ROUTES.INITIAL_PAGE :   ROUTES.UNDER_MAINTENANCE }>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="redirect home"
                            sx={{ mr: 2 }}
                        >
                            <Grid sx={{ display: { xs: "", md: "flex" } }}>
                                <img src={imagePath("logo.png")} />
                            </Grid>
                        </IconButton>
                    </Link>

                    <Box sx={{ flexGrow: 1 }} />

                    {/* -----Right hearder menu------ */}

                    {
                        (userType != CONSTANTS.USER_TYPE_ADMIN && userStatus == "true") ||
                        (userType == CONSTANTS.USER_TYPE_SUPER_ADMIN && userStatus == "true" ||userStatus == "false" ) ||
                        (userType == CONSTANTS.USER_TYPE_ADMIN && userStatus == "false")  ?  
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                                {/* ----MailIcon----- */}
                                {/* <IconButton
                                    size="large"
                                    aria-label="show 4 new mails"
                                    color="inherit"
                                >
                                    <MailIcon />
                                </IconButton>  */}
                           
                                {/* -----SettingIcon----- */}
                                <IconButton
                                    size="large"
                                    aria-label="show 17 new notifications"
                                    color="inherit"
                                    aria-haspopup="true"
                                    onClick={handleSettingsMenuOpen}
                                >
                                    <SettingsIcon />
                                </IconButton> 

                                {/* ----ProfileIcon------ */}
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton> 

                                {/* -----HelpIcon----- */}
                                {/* <IconButton size="large" edge="end" color="inherit">
                                    <QuestionMarkIcon />
                                </IconButton>  */}

                                <IconButton size="small" color="inherit" aria-haspopup="true">
                                    <Select
                                        sx={{
                                            "& > fieldset": { border: "none" },
                                            color: "white",
                                            "& .MuiSvgIcon-root": {
                                                color: "white",
                                            },
                                        }}
                                        value={lang}
                                        onChange={(e) => handleLanguageChange(e.target.value as string)}
                                    >
                                        {Language.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    selected={item.value == lang ? true : false}
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.key}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </IconButton> 
                            </Box> 
                            :  
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>

                                {/* ----MailIcon----- */}
                                {/* <IconButton
                                size="large"
                                aria-label="show 4 new mails"
                                color="inherit"
                            >
                                <MailIcon />
                            </IconButton> */}
                           
                                {/* -----SettingIcon----- */}
                                {/* <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                                aria-haspopup="true"
                                onClick={handleSettingsMenuOpen}
                            >
                                <SettingsIcon />
                            </IconButton> */}

                                {/* ----ProfileIcon------ */}
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>

                                {/* -----HelpIcon----- */}
                                {/* <IconButton size="large" edge="end" color="inherit">
                                <QuestionMarkIcon />
                            </IconButton> */}

                                <IconButton size="small" color="inherit" aria-haspopup="true">
                                    <Select
                                        sx={{
                                            "& > fieldset": { border: "none" },
                                            color: "white",
                                            "& .MuiSvgIcon-root": {
                                                color: "white",
                                            },
                                        }}
                                        value={lang}
                                        onChange={(e) => handleLanguageChange(e.target.value as string)}
                                    >
                                        {Language.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    selected={item.value == lang ? true : false}
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.key}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </IconButton>

                            </Box>
                           
                    }

                 
                    
                  
                    {/* -----Mobile Menu Icon----- */}
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {renderSettingsMenu}
            {isMsgRequired ? <NotificationMessage isCookiePresent={isCookiePresent} isMsgRequired={isMsgRequired} msgVisibility={msgVisibility}/> : "" }
        </Box>
    );
}
