import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, TextField, Box, Button, Alert, Typography } from "@mui/material";

import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import OTPService from "../service/OTPService";
import AuthService from "../service/AuthService";
import { useNavigate } from "react-router";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import CONSTANTS from "../../../constants/constants";
import { Link } from "react-router-dom";

const SendOTP = (props: any) => {
    const { showSnackbar,handleLoader } = props;

    /* Language translate function*/
    const [t, i18n] = useTranslation();
    /* Language translate function*/

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [check, setCheck] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successAlert, setSuccessAlert] = useState(false);

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("send_otp.please_enter_valid_email"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleOnBlur = (e: any) => {
        const { name } = e.target;

        validator.current.showMessageFor(name);
    };

    //validation

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        name === "email" ? setEmail(value) : null;

        validator.current.hideMessageFor(name);
    };
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };
    useEffect(() => {
        if (check == true) {
            const payload = { email };
            const isHitDummyURL = false;
            new OTPService(payload, isHitDummyURL)
                .generate()
                .then(() => {
                    navigate(ROUTES.VERIFY_OTP, { state: { email: email } });
                    setSuccessAlert(true);
                })
                .catch((err) => {
                    showSnackbar(err?.message, false);
                });
        }
    }, [error, check]);

    const handleSubmit = (e: any) => {
        
        if (validator.current.allValid()) {
            handleLoader(true);
            const payloadEmail = { email, otpType: CONSTANTS.OTP_PASSWORD_TYPE }; 
            const isHitDummyURLEmail = false;
            new AuthService(payloadEmail, isHitDummyURLEmail)
                .verifyEmail()
                .then((res) => {
                    handleLoader(false);
                    setCheck(true);
                    showSnackbar(res, true);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, true);
                });
        } else {
            validator.current.showMessages();
        }
    };



    return (
        <>
            <div className="main">
                {/* ForgotPassword page starts here */}
                
                <Container>
                    <Grid container>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img
                                className="logo-img"
                                alt="cainz"
                                src={imagePath("logo-large.png")}
                            />
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    value={email}
                                    name="email"
                                    label={t("send_otp.email_address")}
                                    variant="outlined"
                                    onChange={handleChange}
                                    error={error ? true : false}
                                    helperText={error}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("email", email, [
                                    "required",
                                    "email",
                                ])}

                                <Grid item xs={12} md={12} sx={{ my: 2 }}>
                                    <Button fullWidth onClick={handleSubmit}>
                                        {t("send_otp.sendotp")}
                                    </Button>
                                </Grid>
                                <Link to={ROUTES.INITIAL_PAGE}>
                                    <Typography className="Order_id_link float_right">{t("send_otp.back_to_login")}</Typography>                           
                                </Link>
                            </Grid>
                        </Grid>

                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                </Container>
               
            </div>
            {/* ForgotPassword page ends here */}
        </>
    );
};

export default SendOTP;
