interface CommonType {
    isStoreCreated?: boolean | null,
    loggedInUserEmail?: string,
    loggedInUserName?: string,
    loggedInUserType?: number | null,
    isMaintenance?: string
    perPageLimit?: number | null,
    isOpenProgressbar?: any | any,
    isFileNameStored?: string,
    isProgressbarMessage?: number;
    isUpload?:boolean;
    cryptoMessage?:boolean;
    newPermission:any;
    unregisteredImageStatus:any;
}


export const commonInitialValue: CommonType = {
    isStoreCreated: null,
    loggedInUserEmail: "",
    loggedInUserName: "",
    loggedInUserType: null,
    perPageLimit:null,  
    isMaintenance: "false",
    isOpenProgressbar: false,
    isFileNameStored: "",
    isProgressbarMessage: 0,
    isUpload:true,
    cryptoMessage:true,
    newPermission:null,
    unregisteredImageStatus:"none"
};

export default CommonType;