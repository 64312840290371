import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    IconButton,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    InputAdornment
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CONSTANTS, { LIST_LIMIT } from "../../constants/constants";
import Pagination from "../common/Pagination";
import PageTitle from "../common/PageTitle";
import Skeleton from "../common/Skeleton";
import MUIDataTable from "mui-datatables";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import { snackbarActions } from "../../redux/features/snackbar/snackbarSlice";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CommonButton from "../common/Button";
import UnregisteredImageService from "./services/UnregisteredImageService";
import DownloadButton from "../common/DownloadBtn";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import Utility from "../../utils/Utility";
import SearchIcon from "@mui/icons-material/Search";


const UnregisteredImages = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    const [t] = useTranslation();
    const utility = new Utility;
    const dispatch = useAppDispatch();
    const pathName = window.location.pathname;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const userType = useAppSelector((state) => state.common.loggedInUserType);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.UNREGISTERED_IMAGE_LIST_LIMIT : perPageLimit);
    const [unregisteredImageData, setUnregisteredImageData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    localStorage.setItem(pathName, currentPage.toString());
    const firstPage = Number(localStorage.getItem(pathName));
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(false);
    const [searchedStore, setSearchedStore] = useState<string>("");
    const [searchedProduct, setSearchedProduct] = useState<string>("");
    const [isSearchChange, setIsSearchChange] = useState<boolean>(false);
    const [hideColumnArr, setHideColumnArr] = useState<any>([]);
    const [colFilter, setColFilter] = useState<string>("");
    const [direction, setDirection] = useState<string>("");
    const [colName, setColName] = useState<string>("");
    const [refreshTime, setRefreshTime] = useState<any>();
    const [loading, setLoading] = useState(true);

    const apiData: any[][] = [];

    const [publicationStatus, setPublicationStatus] = useState<any>("none");

    const handlePublicationStatus = (event: any) => {
        setPublicationStatus(event.target.value);
    };

    useEffect(() => {
        if (colFilter || direction) {
            unregisteredImages(firstPage, limit);
        }
    }, [colFilter, direction]);

    const onStoreSearchApicall = () => {
        if (isSearchChange) {
            unregisteredImages(1, limit);
        }
    };

    const onsearchProductApiCall = () => {
        if (isSearchChange) {
            unregisteredImages(1, limit);
        }
    };

    const storePropItem = [
        onStoreSearchApicall, searchedStore
    ];

    const productsPropItem = [
        onsearchProductApiCall, searchedProduct
    ];
    lazyTypingUtils(storePropItem);
    lazyTypingUtils(productsPropItem);

    useEffect(() => {
        unregisteredImages(currentPage, limit);
    }, [publicationStatus]);


    const unregisteredImages = (currentPage: number, limit: number) => {
        setIsSearchChange(false);
        setSkeleton(true);
        const isHitDummyURL = false;
        new UnregisteredImageService({}, isHitDummyURL)
            .unregisteredImageList(currentPage, searchedStore?.trim(), searchedProduct?.trim(), limit, colFilter, direction, publicationStatus)
            .then((res) => {
                setUnregisteredImageData(res?.unregisteredImageLogs);
                if (res?.pagination?.totalCount >= 0) {
                    setSkeleton(false);
                }
                setLoading(false);
                setRefreshTime(res?.lastRefreshTimestamp);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setLimit(limit);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    useEffect(() => {
        dispatch(snackbarActions.HIDE_SNACKBAR(false));
    }, []);

    const handleStoreInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearchedStore(value);
    };

    const handleProductInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearchedProduct(value);
    };

    const handleRefresh = () => {
        const payload = {};
        const isHitDummyURL = false;
        new UnregisteredImageService(payload, isHitDummyURL)
            .refreshUnregisteredImageService()
            .then((res) => {
                showSnackbar(res.message, true);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };


    const apiTableData = () => {
        {
            unregisteredImageData?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    item?.storeCode,
                    item?.sellerName,
                    item?.productCode,
                    item?.cainzProductCode,
                    item?.productName,
                    item?.publicationStatus == 0 ? t("unregistered_images.private") : t("unregistered_images.public"),
                );
            });
        }
    };

    apiTableData();


    const handleSorting = (changedColumn: any) => {
        if (colName != changedColumn) {
            setDirection("asc");

        } else {
            setDirection(direction == "asc" ? "desc" : "asc");
        }

        setColName(changedColumn);
        const columnName = changedColumn == "Store Code" || changedColumn == "ストアコード" ? "store_code" : changedColumn == "Seller Name" || changedColumn == "出品者名" ? "seller_name" : changedColumn == "Product Code" || changedColumn == "商品コード" ? "product_code" : changedColumn == "Cainz Product Code" || changedColumn == "カインズ商品コード" ? "cainz_product_code" : changedColumn == "Product Name" || changedColumn == "商品名" ? "product_name" : "";
        setColFilter(columnName);

    };

    const columns = [
        {
            name: t("unregistered_images.s_no"),
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                setCellHeaderProps: () => ({ className: "seller-no-column" }),
                setCellProps: () => ({ className: "seller-no-column break-word-column" }),
            },
        },
        {
            name: t("unregistered_images.store_code"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("ストアコード") ? false : true,
                setCellHeaderProps: () => ({ className: "seller-code-column" }),
                setCellProps: () => ({ className: "seller-code-column break-word-column" }),
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={() => handleSorting(data.name)} >
                                {colFilter != "store_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },

        {
            name: t("unregistered_images.seller_name"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("出品者名") ? false : true,
                setCellHeaderProps: () => ({ className: "seller-name-column" }),
                setCellProps: () => ({ className: "seller-name-column break-word-column" }),
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={() => handleSorting(data.name)} >
                                {colFilter != "seller_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },

        {
            name: t("unregistered_images.product_code"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("商品コード") ? false : true,
                setCellHeaderProps: () => ({ className: "cpc-column" }),
                setCellProps: () => ({ className: "cpc-column break-word-column" }),
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={() => handleSorting(data.name)} >
                                {colFilter != "product_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: t("unregistered_images.cainz_product_code"),
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                setCellHeaderProps: () => ({ className: "cpc-column" }),
                setCellProps: () => ({ className: "cpc-column break-word-column" }),
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={() => handleSorting(data.name)} >
                                {colFilter != "cainz_product_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: t("unregistered_images.product_name"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("商品名") ? false : true,
                setCellHeaderProps: () => ({ className: "product-name-column" }),
                setCellProps: () => ({ className: "product-name-column break-word-column" }),
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={() => handleSorting(data.name)} >
                                {colFilter != "product_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: t("unregistered_images.public_flag"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("公開フラグ") ? false : true,
                setCellHeaderProps: () => ({ className: "flag-column" }),
                setCellProps: () => ({ className: "flag-column break-word-column" }),
            },
        }
    ];

    const CsvResponse = () => {
        showSnackbar(t("unregistered_images.download_started"), true);
        const isHitDummyURL = false;
        handleLoader(true);
        new UnregisteredImageService({}, isHitDummyURL)
            .downloadCsv(searchedStore?.trim(), searchedProduct?.trim(), limit, colFilter, direction, publicationStatus)
            .then((res: any) => {
                handleOnExport(res?.unregisteredImageLogs);
                setSkeleton(false);
                handleLoader(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
                handleLoader(false);
            });
    };

    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        const columnMap = {
            "ストアコード": "storeCode",
            "出品者名": "sellerName",
            "商品コード": "productCode",
            "カインズ商品コード": "cainzProductCode",
            "商品名": "productName",
            "公開フラグ": "publicationStatus"
        };
    
        downloadArray?.forEach((item: any) => {
            const row: any = {};
            Object.entries(columnMap).forEach(([columnName, key]) => {
                // Only include columns not in hideColumnArr
                if (!hideColumnArr.includes(columnName)) {
                    row[columnName] = key === "publicationStatus"
                        ? item?.publicationStatus == 0
                            ? "非公開"
                            : "公開"
                        : item?.[key];
                }
            });
            downloadData.push(row);
        });
        const fileName = `ストア画像未設定一覧_${utility.getCurrentTimestamp()}.xlsx`;
        downloadDataToExcel(downloadData, fileName, "ストア画像未設定一覧", true);
        handleLoader(false);
    };

    return (
        <>
            <div className="main">
                <Container>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <PageTitle title={t("unregistered_images.page_title")} />
                        </Grid>
                        <Grid item className="category-update" xs={12} sm={6} md={6} lg={6} sx={{ py: 4}}>
                            {refreshTime && <Box component="span" sx={{fontWeight:"500"}}>
                                {t("unregistered_images.last_refresh")} {refreshTime}
                            </Box>}
                            {
                                <Box component="span">
                                    <CommonButton route={window.location.pathname} functionToExecute={() => handleRefresh()} btnName={t("unregistered_images.refresh")} /> 
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Container>

                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <TextField
                                id="input-with-icon-textfield"
                                label={t("unregistered_images.search_by_store_code")}
                                onChange={handleStoreInputChange}
                                value={searchedStore}
                                variant="outlined"
                                fullWidth
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <TextField
                                id="input-with-icon-textfield"
                                label={t("unregistered_images.search_by_product_name")}
                                onChange={handleProductInputChange}
                                value={searchedProduct}
                                variant="outlined"
                                fullWidth
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">{t("unregistered_images.publication_status")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={publicationStatus}
                                    label="Publication Status"
                                    onChange={handlePublicationStatus}
                                >
                                    <MenuItem value={"false"}>{t("unregistered_images.private")}</MenuItem>
                                    <MenuItem value={"true"}>{t("unregistered_images.public")}</MenuItem>
                                    <MenuItem value={"none"} >{t("unregistered_images.all")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={0} sm={0} md={3} lg={2}></Grid>
                        <Grid item xs={12} sm={12} md={3} lg={2} sx={{verticalAlign: "center"}}>
                            <DownloadButton sx={{float: "right", width:"260px"}} route={window.location.pathname} functionToExecute={() => CsvResponse()} btnName={t("unregistered_images.download")} disableCondition={unregisteredImageData?.length == 0 || loading || (totalCount > CONSTANTS.MAX_DOWNLOAD_LIMIT_UNREGISTER_IMAGE) ? true : false} />
                        </Grid>
                        {
                            (totalCount > CONSTANTS.MAX_DOWNLOAD_LIMIT_UNREGISTER_IMAGE) &&
                                <Grid item className="unregistered_refresh" xs={12} sm={12} md={12} lg={12} sx={{ justifyContent: "flex-end" }}>
                                    <Typography variant="body1" sx={{ display: "flex", mt: -2, justifyContent: "flex-end" }}>
                                        {t("unregistered_images.download_limit_reached")}
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                    {skeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="10"
                            my={0}
                        />
                    ) : (
                        <Container className="unregistered-images-fixed-table-width">

                            <MUIDataTable
                                title={""}
                                columns={columns}
                                data={apiData}
                                options={{
                                    pagination: false,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    search: false,
                                    selectableRowsHideCheckboxes: true,
                                    textLabels: {
                                        body: {
                                            noMatch: t("table_text.no_match").toString(),
                                        },
                                        viewColumns: {
                                            title: t("table_text.show_columns").toString(),
                                            titleAria: "Show/Hide Table Columns",
                                        },
                                        toolbar: {
                                            search: t("table_text.search").toString(),
                                            viewColumns: t("table_text.view_columns").toString(),
                                        },
                                    },
                                    searchPlaceholder: t(
                                        "table_text.search_placeholder"
                                    ).toString(),
                                    responsive: "standard",
                                    onSearchChange: (searchText: any) => {
                                        setSearchedProduct(searchText ? searchText : "");
                                    },
                                    onViewColumnsChange: (changedColumn: string, action: string) => {
                                        const tempHideColArr = [...hideColumnArr];
                                        if (action === "remove") {
                                            changedColumn == "Store Code" ?
                                                tempHideColArr.push("ストアコード") :
                                                changedColumn == "Seller Name" ?
                                                    tempHideColArr.push("出品者名") :
                                                    changedColumn == "Product Code" ?
                                                        tempHideColArr.push("商品コード") :
                                                        changedColumn == "Product Name" ?
                                                            tempHideColArr.push("商品名") :
                                                            changedColumn == "Public Flag" ?
                                                                tempHideColArr.push("公開フラグ") :
                                                                tempHideColArr.push(changedColumn);
                                            setHideColumnArr(tempHideColArr);
                                        }
                                        else {
                                            let index = -1;
                                            changedColumn == "Store Code" ?
                                                index = tempHideColArr.indexOf("ストアコード") :
                                                changedColumn == "Seller Name" ?
                                                    index = tempHideColArr.indexOf("出品者名") :
                                                    changedColumn == "Product Code" ?
                                                        index = tempHideColArr.indexOf("商品コード") :
                                                        changedColumn == "Product Name" ?
                                                            index = tempHideColArr.indexOf("商品名") :
                                                            changedColumn == "Public Flag" ?
                                                                index = tempHideColArr.indexOf("公開フラグ") :
                                                                index = tempHideColArr.indexOf(changedColumn);
                                            tempHideColArr.splice(index, 1);
                                            setHideColumnArr(tempHideColArr);
                                        }
                                    }
                                }}
                            />

                        </Container>
                    )}
                    <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        pageChange={unregisteredImages}
                        totalPage={totalPages}
                        rowsPerPage={limit}
                        rowsPerPageChange={setLimit}
                    />
                </Container>
            </div>
            {/* Table data ends here */}
        </>
    );
};

export default UnregisteredImages;
