import React from "react";
import * as ExcelJS from "exceljs";


const downloadDataToExcel=(data:Array<object>,fileName:string,sheetName:string, alignmentMiddle = false  )=>{
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);
    // Add headers
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);
    // Add data
    data.forEach((item:any) => {
        const row = Object.values(item);
        worksheet.addRow(row);
    });
    // Set font style for the entire sheet
    const fontStyle = { name: "Yu Gothic" };
    const alignmentStyle: Partial<ExcelJS.Alignment> = { vertical: "middle" };
    worksheet.eachRow((row) => {
        row.eachCell((cell) => {
            cell.font = fontStyle;
            if (alignmentMiddle) {
                cell.alignment = alignmentStyle;
            }
        });
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
    });
};

export default downloadDataToExcel;