/* eslint-disable no-useless-escape */
const CONSTANTS = {
    EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
    PASSWORD_REGEX:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-=+{}\[\]\|;:',.<>"\/?])[A-Za-z\d`~!@#$%^&*()_\-=+{}\[\]\|;:',.<>"\/?]{8,16}$/,
    USER_TYPE_SUPER_ADMIN : 0,
    USER_TYPE_ADMIN : 1,
    USER_TYPE_SELLER : 2,
    STATUS_ACTIVE: 1,
    STATUS_INACTIVE: 0,
    PASSWORD_TEXT:  "**********",
    JWT_SECRET_KEY: "cainz-ec-2023",
    OTP_PASSWORD_TYPE: 0,
    OTP_EMAIL_TYPE: 1,
    OTP_ERROR_MSG:"確認コードが一致しません",
    FIRST_PAGE:1,
    SEARCH_DELAY_TIME:1000,
    DOWNLOAD_EXCEL_SHEET_LIMIT:9999999999,
    RESTRICTED_KEYWORD:["cainz"],
    RMS_PERMISSION_VIEW :1,

  
    SHEET_HEADER: [
        "商品コード",
        "カインズ商品コード",
        "商品名",
        "商品名（カナ）",
        "ブランド",
        "製造元",
        "消費税区分",
        "税込商品価格",
        "個別送料",
        "最大注文数量",
        "酒類区分",
        "公開フラグ",
        "公開開始日",
        "公開終了日",
        "商品概要説明",
        "配送日数",
        "ECカテゴリ",
        "スペック項目名1",
        "スペック項目値1",
        "スペック項目名2",
        "スペック項目値2",
        "スペック項目名3",
        "スペック項目値3",
        "スペック項目名4",
        "スペック項目値4",
        "スペック項目名5",
        "スペック項目値5",
        "スペック項目名6",
        "スペック項目値6",
        "スペック項目名7",
        "スペック項目値7",
        "スペック項目名8",
        "スペック項目値8",
        "スペック項目名9",
        "スペック項目値9",
        "スペック項目名10",
        "スペック項目値10",
    ],
    PAYMENT_SHEET_HEADER: [
        "id",
        "transfer_date",
        "transfer_type",
        "ts_consignor_code",
        "transfer_consignor_name",
        "tsf_institution_code",
        "tsf_institution_name_kana",
        "tsf_branch_code",
        "tsf_branch_name_kana",
        "ts_deposit_type",
        "ts_account_number",
        "payee_code",
        "payee_name",
        "payee_financial_institution_code",
        "tdf_financial_institution_name_kana",
        "payee_financial_branch_code",
        "tdf_branch_name_kana",
        "transfer_destination_deposit_item",
        "transfer_account_number",
        "transfer_payee_name",
        "transfer_amount", 
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "deleted_at",
        "deleted_by"   
    ],
    ORDER_SHEET_HEADER:[
        "id",
        "seller_code",
        "seller_name",
        "order_date",
        "order_confirmation_date",
        "order_id",
        "status",
        "cancellation_datetime",
        "cancel_by",
        "cancellation_reason_id",
        "payment_method_id",
        "payment_method_name",
        "arrival_date_designation",
        "desired_delivery_day",
        "desired_delivery_time",
        "delivery_date",
        "delivery_box_flag",
        "gift_flag",
        "shipment_completion_date",
        "delivery_note_print_date",
        "delivery_note_number",
        "total_product_price_regular",
        "total_product_price_reduced",
        "total_product_price_non_taxable",
        "total_product_price",
        "consumption_tax_amount_regular",
        "consumption_tax",
        "consumption_tax_amount",
        "total_shipping_price",
        "total_order_amount",
        "total_point_awarded",
        "point_usage_amount",
        "orderer_email",
        "orderer_name",
        "name_of_orderer",
        "orderer_kana_sei",
        "orderer_kana_mei",
        "orderer_postal_code",
        "orderer_prefecture",
        "orderer_address_1",
        "orderer_address_2",
        "orderer_address_3",
        "orderer_company_name",
        "orderer_department_name",
        "orderer_phone_number",
        "shipping_surname",
        "shipping_first_name",
        "shipping_address_kana_sei",
        "shipping_address_kana_mei",
        "shipping_postal_code",
        "shipping_prefecture",
        "shipping_address_1",
        "shipping_address_2",
        "shipping_address_3",
        "shipping_company_name",
        "shipping_department_name",
        "shipping_phone_number",
        "seller_memo",
        "seller_cancelable_flag",
        "oms_integration_status",
        "oms_linkage_date",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "created_at_external",
        "created_by_external",
        "updated_at_external",
        "updated_by_external"
    ],
    MAX_DOWNLOAD_LIMIT_UNREGISTER_IMAGE: 10000
};
  
export const LIST_LIMIT = {
    PAGINATION_LIST_LIMIT: 15,
    STORE_LIST_LIMIT: 15,
    ADMIN_LIST_LIMIT: 15,
    SELLLER_PAYMENT_LIST_LIMIT: 15,
    JOURNAL_DATA_LIST_LIMIT: 15,
    ACTIVITY_LOG_LIST_LIMIT: 15,
    PAYMENT_HISTORY:15,
    ENCRYPTION_DECRYPTION_LIMIT:15,
    STUCKED_TRANSACTION_LIMIT:15,
    UNREGISTERED_IMAGE_LIST_LIMIT: 15,
    CATEGORY_LIST_LIMIT:15
};

export const USER_LIST_CONSTANTS = {
    STATUS_PENDING: 0,
    STATUS_EXPIRED: 3,
    STATUS_ACCEPTED: 1,
};
export const SHEET_NAME = {
    SALES_HISTORY:"支払履歴",
    SALES_LIST:"ストア売上一覧",
    ACTIVITY_LOG:"activity log",
    ORDER_DATA:"注文テーブル",
    PAYMENT_DATA:"支払テーブル",
    CATEGORY_LIST:"カテゴリ一覧"
};

export const DATE_CONFIG = {
    GET_DATE_20: 20
};
export const NOTIFICATION = {
    MAX_LINE: 4.2
};

export const ROLE_MANAGEMENT_SYSTEM = {
    PERMISSION_ALLOWED : 1,
    PERMISSION_NOTALLOWED : 0,  
};


export default CONSTANTS;
