import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import PageTitle from "../common/PageTitle";
import { useTranslation } from "react-i18next";
import TransactionService from "./services/transactionService";
import Pagination from "../common/Pagination";
import CONSTANTS, { DATE_CONFIG, LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";
import SimpleReactValidator from "simple-react-validator";
import Skeleton from "../common/Skeleton";
import MUIDataTable from "mui-datatables";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Utility from "../../utils/Utility";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import "dayjs/locale/ja"; // Import Japanese locale for dayjs
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import { useAppSelector } from "../../redux/hooks";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadButton from "../common/DownloadBtn";


const PaymentReports = (props: any) => {

    const { showSnackbar } = props;
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const utility = new Utility();

    const [transactionList, setTransactionList] = useState<any>();
    const [currentPage, setCurrentPage] = useState<any>(firstPage);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit );
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [selectedValue, setSelectedValue] = React.useState("a");
    const [customDateModel, setCustomDateModel] = useState(true);
    const [pastNumberModel, setPastNumberModel] = useState(false);
    const [settlementModel, setSettlementModel] = useState(false);
    const [valueFrom, setValueFrom] = useState<Date | null>(null);
    const [valueTo, setValueTo] = useState<Date | null>(null);
    const [transactionType,setTransactionType]=useState("");
    const [transactionStatus,setTransactionStatus]=useState("");
    const [search, setSearch] = useState<string>("");
    const [sellerValue, setSellerValue] = useState("");
    const [orderId, setOrderId] = useState<string>("");
    const [settlementPeriod, setSettlementPeriod] = useState<string>("");
    const [depositDate, setDepositDate] = useState<string>("");
    const [sellerName, setSellerName] = useState<any>([]);
    const [pastNoOfDays, setPastNoOfDays] = useState<string>("");
    const [fromParam,setFromParam] = useState<any>("");
    const [toParam,setToParam] = useState<any>("");
    const [skeleton, setSkeleton] = useState(true);
    const apiData: any[][] = [];
    const[t] = useTranslation();
    const [, forceUpdate] = useState(0);
    const [resetData, setResetData] = useState(false);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [noOptions, setNoOptions]=useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");

    useEffect(() => {
        forceUpdate(1);
        document.querySelector(".from-date-seller-picker input")?.setAttribute("disabled","true");
        document.querySelector(".to-date-seller-picker input")?.setAttribute("disabled","true");
    });
 
    const validatorCustomDate = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    const validtorSettlement = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    const validatorPastDate = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );


    useEffect(()=>{
        if(search||depositDate||transactionType||transactionStatus||resetData||colFilter||direction){
            transactionData(firstPage, limit);
        }
        if(search==""){
            setSellerName([]);
        }
    },[search,depositDate, transactionType, transactionStatus, resetData, colFilter, direction]);

    const onsearchApicall = () =>{
        if(isSearchChange){
            transactionData(firstPage, limit);
        }
    };
    
    const propItem = [
        onsearchApicall,orderId
    ];
    lazyTypingUtils(propItem);

    useEffect(()=>{
        transactionData(firstPage, limit);
        
    },[]);


    const transactionData = (currentPage: number, limit: any) => {
        setIsSearchChange(false);
        setResetData(false);
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new TransactionService(payload, isHitDummyURL)
            .transactionList(currentPage, limit, sellerValue, transactionType, transactionStatus, pastNoOfDays, toParam, fromParam, settlementPeriod, depositDate, orderId ,colFilter,direction)
            .then((res) => {  
                setTransactionList(res);
                setCurrentPage(currentPage);
                setLimit(limit);
                setTotalCount(res?.pagination.totalCount);
                setTotalPages(res?.pagination.totalPages);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });

        document.querySelector(".from-date-seller-picker input")?.setAttribute("disabled","true");
        document.querySelector(".to-date-seller-picker input")?.setAttribute("disabled","true");
    };


    const paymentReportSellerNameInput = (search:string) => {
        const payload = {};
        const isHitDummyURL = false;
        new TransactionService(payload, isHitDummyURL)
            .PaymentReportSearchSellerName(search)
            .then((res:any) => {
                res?.paymentReportNameLogs.length > 0 ? handleSearchDropdown(res?.paymentReportNameLogs) : setSellerName([]);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    const handleSearchHistoryFilterChange = (e:any) =>{

        e?.target?.value?.length > 0 ? setNoOptions(true):setNoOptions(false);

        if(e?.target?.value==""){
            setSellerName([]);
        }
        if(e?.target?.value?.trim()!=""){
            paymentReportSellerNameInput(e?.target?.value);  
               
        }else{
            setSellerName([]);
        }
    };


    const handleSearchValue = (e: any, value: any) => {
        if(value){
            setSearch(value?.label);
            setSellerValue(value?.value);
        }else{
            setSearch("");
            setSellerValue("");
        }
    };
  
    const handleSearchDropdown = (dropdown:any) =>{
        const uniquePartnerNames = new Set();
        let dropdownList: { label: any; value: any; }[] = [];
        if(dropdown.length > 0){
            dropdown.map((item:any)=>{
                if (!uniquePartnerNames.has(item.partnerName)) {
                    uniquePartnerNames.add(item.partnerName);
                    dropdownList.push({
                        label: `${item?.partnerName} (${item?.storeCode})`,
                        value: item.storeCode,
                    });
                }
            });
        }
        else{
            dropdownList = [];
        }
        setSellerName(dropdownList);
    };

    const handleSellerClick = (e: any) =>{
        setSellerName([]);
    };


    const downloadSalesListData = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        new TransactionService(payload, isHitDummyURL)
            .downloadPaymentRepoart( sellerValue,transactionType, transactionStatus,pastNoOfDays,toParam,fromParam,settlementPeriod,depositDate,orderId,colFilter,direction)
            .then((res: any) => {
                handleOnExport(res?.transactionsLogs);
                setSkeleton(false);
            })
            .catch((err:any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
                setLoading(false);
            });
    };


    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item :any)=>{
            downloadData.push({
                "日付":item?.date?.split("T")[0],
                "出品者名":item?.sellerName,
                "種別":item?.kinds,
                "ステータス":item?.deposit_status,
                "注文番号":item?.order_id,
                "受注金額":item?.total_order_amount,
                "ポイント利用額":item?.point_usage_amount,
                "ポイント付与額":item?.total_point_awarded,
                "Cainz手数料額":item?.cainz_fee,
                "売上額":item?.seller_seles_amount,     
                "入金処理日":item?.sales_transfer_date || item?.refund_transfer_date,
                "入金予定日":item?.transfer_execute_date
            });
        });
        // const wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.json_to_sheet(downloadData);
        // XLSX.utils.book_append_sheet(wb, ws, "ストア売上一覧");
        // XLSX.writeFile(wb, "ストア売上一覧.xlsx");
        const fileName="ストア売上一覧.xlsx";
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.SALES_LIST);
        setLoading(false);
   
    };

    const handleSettlement = (event: any) => {
        setSelectedValue(event.target.value);
        setCustomDateModel(false);
        setPastNumberModel(false);
        setSettlementModel(true);
        setValueFrom(null);
        setValueTo(null);
        setPastNoOfDays("");
        setFromParam("");
        setToParam("");
       
    };

    const handlePastNumber = (event: any) => {
        setSelectedValue(event.target.value);
        setCustomDateModel(false);
        setPastNumberModel(true);
        setSettlementModel(false);
        setValueFrom(null);
        setValueTo(null);
        setSettlementPeriod("");
        setFromParam("");
        setToParam("");
    };

    const handleCustomRange = (event: any) => {  
        setSelectedValue(event.target.value);
        setCustomDateModel(true);
        setPastNumberModel(false);
        setSettlementModel(false);
        setPastNoOfDays("");
        setSettlementPeriod("");  
       
    };
    const configurationDate = new Date(transactionList?.firstSalesDate);
    const currentDate = new Date();
    const diffInConfigurationDate = (currentDate.getFullYear() - configurationDate.getFullYear()) * 12 +
    (currentDate.getMonth() - configurationDate.getMonth());
    const settlement_period_filter_options = [];
    const deposit_date_filter_option=[];

    for (let i = diffInConfigurationDate+2; i >=0 ; i--) {
        const month = configurationDate.getMonth() + i;
        const year = configurationDate.getFullYear();
        if(i>=2){
            const startDateForDepositDate = new Date(year, month, 1);
            const deposit_date = utility.getFormattedDateTime(startDateForDepositDate, DATE_CONFIG.GET_DATE_20);
            deposit_date_filter_option.push(deposit_date);
        }
        if(i<=diffInConfigurationDate){
            const startDate = new Date(year, month, 1);
            const endDate = new Date(year, month + 1, 0);
            const formattedDate = `${utility.getFormattedDateTime(startDate)}-${utility.getFormattedDateTime(endDate)}`;
            settlement_period_filter_options.push(formattedDate);
        }
    }
    const handleTransactionType = (event: SelectChangeEvent) => {
        setTransactionType(event.target.value);
    };
    const handleTransactionStatus = (event: SelectChangeEvent) => {
        setTransactionStatus(event.target.value);
    };
    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setOrderId(value);
    };

    const handlePastNoOfDays = (e:any)=>{
        const { value } = e.target;
        setPastNoOfDays(value);
    };
    const handleSettlementPeriod = (e:any)=>{
        const { value } = e.target;
        setSettlementPeriod(value);
    };

    const handleDepositDate = (e:any)=>{
        const { value } = e.target;
        setDepositDate(value);
    };


    const handleDateFrom = (value:any) =>{
        const {$D, $M, $y} = value;
        let month = $M + 1;
        let dateto = $D;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        setFromParam(`${$y}/${month}/${dateto}`);
        setValueFrom(value);
    };

  
    const handleDateTo = (value:any) =>{
        const {$D, $M, $y} = value;
        let month = $M + 1;
        let dateto = $D;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        setToParam(`${$y}/${month}/${dateto}`);
        setValueTo(value);
    };

  
    
    const handleSubmit = () =>{
        const validator = settlementModel ? 
            validtorSettlement 
            : (pastNumberModel ? 
                validatorPastDate : 
                validatorCustomDate );

        if (validator.current.allValid()) {
            transactionData(firstPage, limit);     
        }
        else {
            validator.current.showMessages();
        }
    };

    const handleSorting =(changedColumn:any)=>{
        if(colName!=changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        
        setColName(changedColumn);   
        const columnName=changedColumn == "Date" || changedColumn=="日付" ? "date": changedColumn == "Order Number" || changedColumn == "注文番号" ? "order_id":changedColumn == "Scheduled Payment Date" || changedColumn=="入金予定日" ? "transfer_execute_date":changedColumn == "Payment Day" || changedColumn=="入金処理日" ? "payment_day":"";
          
        setColFilter(columnName);
    };
    


    const columns = [
        {
            name: t("pr.date"),
            options: {
                filter: true,
                sort: false,                            
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="date" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>            
                        </Box>
                    );
                },                    
            },
        },
        {
            name: t("pr.seller_name"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("pr.kind"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("pr.deposit_status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("pr.order_number"),
            options: {
                filter: true,
                sort: false,              
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="order_id" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition" />:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>                 
                        </Box>
                    );
                },            
            },
        },
      
        {
            name: t("pr.order_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.amount_of_points_used"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.points_used"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.cainz_fee_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.sales_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.payment_day"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="payment_day" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>                     
                        </Box>
                    );
                },  
            },
        
        },
        {
            name: t("pr.scheduled_payment_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="transfer_execute_date" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>      
                        </Box>
                    );
                },  
            },
        },
     
    ];

    const tableNegativeValue = (value: any) => {
        return (
            <Box className={`${value < 0 ? "sales_history_negative_quantity_red " : "sales_history_negative_quantity_black"}`}>{utility.numberWithCommas(value)}</Box>
        );
    };
    const newTableNegativeValue = (newValue: any, code:any) => {
        return (
            <Link to ={`${ROUTES.ORDER_DETAILS}/${newValue}/${code}`} target="_blank" className="link_color"> <Box className="link_color">{newValue}</Box></Link>         
        );
    };
  

    const apiTableData = () => {
        {
            transactionList?.transactionsLogs?.map((item: any, indexItem: number) => {
                apiData[indexItem] = [];
                apiData[indexItem].push(
                    item?.date,
                    item?.sellerName,
                    item?.kinds ,
                    item?.deposit_status ,
                    newTableNegativeValue(item?.order_id,item?.sellerCode),
                    tableNegativeValue(item?.total_order_amount),
                    tableNegativeValue(item?.point_usage_amount),
                    tableNegativeValue(item?.total_point_awarded),
                    tableNegativeValue(item?.cainz_fee),
                    tableNegativeValue(item?.seller_seles_amount),
                    item?.sales_transfer_date || item.refund_transfer_date,
                    item?.transfer_execute_date
                );
            });
        }
    };
    apiTableData();


    const resetAllFilter = () =>{
        setSellerValue("");
        setSearch("");
        setOrderId("");
        setTransactionStatus("");
        setTransactionType(""); 
        setDepositDate("");
        setCustomDateModel(true);
        setPastNumberModel(false);
        setSettlementModel(false);
        setValueFrom(null);
        setValueTo(null);
        setPastNoOfDays("");
        setFromParam("");
        setToParam("");
        setSettlementPeriod("");
        setSelectedValue("a");
        setResetData(true);    
        setNoOptions(false);
    };  
    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };


    return (

        <>
            <Container className="paymentReport">
                <PageTitle title={t("pr.header_title")} />
                <Grid  className="shipment-confirm-order" sx={{ p: 3, backgroundColor: "#f0f2f2" }}>
                    <Grid container spacing={2} className="sales_list_header" sx={{mr:4}}>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <Autocomplete
                                    freeSolo = {noOptions ? false : true}
                                    className="paymentReport-dropdown"
                                    disablePortal
                                    id="seller-name-filter"
                                    forcePopupIcon={false}
                                    value={search}
                                    onChange={(e,value)=>handleSearchValue(e, value)}
                                    onFocus={()=>{setNoOptions(false);}}
                                    options={sellerName}
                                    disableClearable = {true}
                                    noOptionsText= "データが見つかりません"
                                    renderInput={(params) => <TextField {...params}  label={t("pr.seller_name")} onChange={(e)=>{handleSearchHistoryFilterChange(e); }} 
                                        onMouseEnter={(e)=>handleSellerClick(e)} />}
                                />
                            </FormControl>
                          
                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("pr.deposit_date")}</InputLabel>
                                <Select
                                    className="paymentReport-dropdown"
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={depositDate}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 250,  
                                        },
                                    }}  
                                    onChange={handleDepositDate}
                                    input={
                                        <OutlinedInput label={t("pr.deposit_date")}  />
                                    }
                                    // MenuProps={MenuProps}
                                >                                  
                                    {
                                        deposit_date_filter_option?.map((item, dipositKey)=>{
                                            return(
                                                <MenuItem
                                                    value={item}
                                                    key={dipositKey} >
                                                    {item}                                   
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("pr.transaction_type")}</InputLabel>
                                <Select
                                    className="paymentReport-dropdown"
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    input={
                                        <OutlinedInput label={t("pr.transaction_type")} />
                                    }
                                    onChange={handleTransactionType}
                                    value={transactionType}>
                                    
                                   
                               
                                    <MenuItem value="売上">               
                                        {t("pr.earnings")}
                                    </MenuItem>
                                    <MenuItem value="返金">{t("pr.refund")}</MenuItem>                                                                  
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("pr.transaction_status")}</InputLabel>
                                <Select
                                    className="paymentReport-dropdown"
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    input={
                                        <OutlinedInput label={t("pr.transaction_status")} />
                                    }
                                    onChange={handleTransactionStatus}
                                    value={transactionStatus}

                                    // MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        // style={getStyles(quantity, personName, theme)}
                                        value="入金予定"
                                    >
                                        {t("pr.expected_payment")}
                                    </MenuItem>
                                    <MenuItem value="返金予定">{t("pr.scheduled_refund")}</MenuItem>
                                    <MenuItem value="入金処理済み">{t("pr.deposited")}</MenuItem>
                                    <MenuItem value="返金処理済み">{t("pr.refunded")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className="sale-list-padding">
                            <Box className="radio_button_box">
                                <FormControlLabel checked={selectedValue === "a"} value="a" onChange={handleCustomRange} control={<Radio />} label={t("pr.custom_date_range")} />
                                <FormControlLabel checked={selectedValue === "b"} value="b" onChange={handlePastNumber} control={<Radio />} label={t("pr.past_number_of_days")} />
                                <FormControlLabel checked={selectedValue === "c"} value="c" onChange={handleSettlement} control={<Radio />} label={t("pr.payment_period")} />
                            </Box>
                           
                        </Grid>
                        {
                            settlementModel ? (
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <FormControl sx={{ mt: 1, width: "100%" }}>
                                        <InputLabel>{t("pr.payment_period")}</InputLabel>
                                        <Select
                                            className="settlement"
                                            labelId="demo-multiple-name-label"
                                            id="settlement"
                                            value={settlementPeriod}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: 250,  
                                                },
                                            }}  
                                            input={
                                                <OutlinedInput label={t("pr.payment_period")} />
                                            }
                                            onChange={handleSettlementPeriod}
                                            // MenuProps={MenuProps}
                                        >
                                            {
                                                settlement_period_filter_options?.map((item, settlementKey)=>{
                                                    return(
                                                        <MenuItem
                                                            value={item}
                                                            key={settlementKey}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                           
                                        </Select>
                                        {validtorSettlement.current.message("settlement", settlementPeriod, [
                                            "required",
                                        ])}
                                    </FormControl>
                                </Grid>
                            ) : null
                        }
                        {
                            pastNumberModel ? (
                                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                    <FormControl sx={{ mt: 1, width: "100%" }}>
                                        <InputLabel>{t("pr.past_number_of_days")}</InputLabel>
                                        <Select
                                            className="pastnum"
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            value={pastNoOfDays}
                                            input={
                                                <OutlinedInput label={t("pr.past_number_of_days")} />
                                            }
                                            onChange={handlePastNoOfDays}>
                                            <MenuItem value={1}>{t("pr.1_day")}</MenuItem>
                                            <MenuItem value={3}>{t("pr.3_days")}</MenuItem>
                                            <MenuItem value={7}>{t("pr.7_days")}</MenuItem>
                                            <MenuItem value={30}>{t("pr.30_days")}</MenuItem>
                                            <MenuItem value={60}>{t("pr.60_days")}</MenuItem>
                                            <MenuItem value={90}>{t("pr.90_days")}</MenuItem>
                                        </Select>
                                        {validatorPastDate.current.message("pastnum", pastNoOfDays, [
                                            "required",
                                        ])}
                                    </FormControl>
                                </Grid>
                            ) : null
                        }
                        {
                            customDateModel ? (
                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns} ></LocalizationProvider> */}
                                    <Grid container spacing={2}>
                                        <Grid item lg={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja" dateFormats={{ monthAndYear: "YYYY年MM月" }}>
                                                {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                                                <DatePicker
                                                    className="from-date-seller-picker input"
                                                    label={t("pr.from")} 
                                                    value={valueFrom}
                                                    format="YYYY/MM/DD"
                                                    onChange={handleDateFrom}
                                                    autoFocus={true}
                                                    disableFuture
                                                    
                                                    // disableMaskedInput={true}
                                                    // maxDate={valueTo}
                                                    shouldDisableDate={shouldDisableDateFrom}                                                   
                                                    sx={{ backgroundColor: "white"}}
                                                />
                                                    
                                                {/* </DemoContainer> */}
                                            </LocalizationProvider>
                                        </Grid>
                                       
                                        <Grid item lg={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja" dateFormats={{ monthAndYear: "YYYY年MM月" }}>
                                                {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                                                <DatePicker
                                                    className="to-date-seller-picker input"
                                                    label={t("pr.to")}
                                                    value={valueTo}
                                                    format="YYYY/MM/DD"
                                                    onChange={handleDateTo}
                                                    autoFocus={true}
                                                    disableFuture
                                                       
                                                
                                                    // disableMaskedInput={true}
                                                    // minDate={valueFrom}
                                                    shouldDisableDate={shouldDisableDateTo}                                                   
                                                    sx={{ backgroundColor: "white"}}
                                                />
                                                    
                                                {/* </DemoContainer> */}
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item lg={6}>
                                            {validatorCustomDate.current.message("from-date-seller-picker input", valueFrom, [
                                                "required",
                                            ])}
                                        </Grid>
                                        <Grid item lg={6}>
                                            {validatorCustomDate.current.message("to-date-seller-picker input", valueTo, [
                                                "required",
                                            ])}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                        <Grid item xs={1} sm={2} md={1} lg={1} xl={1} className="submit_btn ">
                            <Button
                                variant="contained"
                                sx={{textTransform: "capitalize"}}
                                onClick={handleSubmit}
                            >
                                {t("pr.submit")}
                            </Button>
                        </Grid>  
                        <Grid item xs={6} sm={4} md={2} lg={2} xl={2} className="reset_btn">
                            <Button
                                variant="contained"                           
                                sx={{textTransform: "capitalize"}}
                                onClick={resetAllFilter}> {t("pr.reset")}
                            </Button>
                        </Grid>                                   
                    </Grid>          
                </Grid>
            </Container>

            <Container sx={{py:2}}>
                <Grid container>
                    <Grid item xs={10} sm={8} md={6} lg={7} xl={8}>
                        {/* <PageTitle title={t("pr.transaction_list")} /> */}
                    </Grid>
                    <Grid item xs={7} sm={5} md={3} lg={3} xl={2} className="pr_search_bar">
                        <TextField
                            // id="input-with-icon-textfield"
                            label={t("pr.search_by_order_id")}                           
                            onChange={handleSearchChange}
                            // onKeyUp={handleKeyPress}
                            value={orderId}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />                       
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={2} xl={2} className="pr_download_btn">
                        <DownloadButton loading={loading} route={window.location.pathname} functionToExecute={downloadSalesListData} className="margin_download_button" btnName={t("pr.download")} disableCondition={loading || transactionList?.transactionsLogs?.length==0}/>                       
                        {/* <Button
                            className="margin_download_button"
                            variant="contained"
                            disabled={loading || transactionList?.transactionsLogs?.length==0}
                            sx={{textTransform: "capitalize"}}
                            onClick={downloadSalesListData}
                        >
                            {t("pr.download")}
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Button>   */}
                    </Grid>                   
                </Grid>
            </Container>
            
            
                    
            {skeleton ? (
                <Skeleton
                    varient="rectangular"
                    width={0}
                    height={60}
                    count="10"
                    my={0}
                />
            ) : (
                       
                <Container className="common_table">
                    <MUIDataTable 
                        title={""}
                        columns={columns}
                        data={apiData}
                        options={{
                            pagination: false,
                            print: false,
                            download: false,
                            filter: false,
                            viewColumns: false,
                            search: false,
                            selectableRowsHideCheckboxes: true,
                            // sortOrder: {
                            //     name: colName,
                            //     direction: direction =="desc"? "desc":"asc",
                            // },
                            textLabels: {
                                body: {
                                    noMatch: t("table_text.no_match").toString(),
                                },
                                viewColumns: {
                                    title: t("table_text.show_columns").toString(),
                                    titleAria: "Show/Hide Table Columns",
                                },
                                toolbar: {
                                    search: t("table_text.search").toString(),
                                    viewColumns: t("table_text.view_columns").toString(),
                                },
                            },
                            searchPlaceholder: t("table_text.search_placeholder").toString(),
                            responsive: "standard",
                            // onColumnSortChange: (changedColumn, direction) => {
                            //     setDirection(direction);
                            //     setColName(changedColumn);               
                            //     const columnName=changedColumn == "Date" || changedColumn=="日付" ? "date": changedColumn == "Order Number" || changedColumn == "注文番号" ? "order_id":changedColumn == "Scheduled Payment Date" || changedColumn=="入金予定日" ? "transfer_execute_date":changedColumn == "Payment Day" || changedColumn=="入金処理日" ? "":"";
                            //     setColFilter(columnName);
                            // },
                             
                        }}
                       
                    />
                </Container>
            )} 
           
          
            
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={transactionData}
                totalPage={totalPages}  
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
        </>
    );
};

export default PaymentReports;
