import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import CommonType, { commonInitialValue } from "../../../interfaces/commonInterface";

const initialState: CommonType = commonInitialValue;

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        SAVE_LOGIN_USER_NAME: (state, action: PayloadAction<string>) => {
            state.loggedInUserName = action.payload;
        },
        IS_STORE_CREATED: (state, action: PayloadAction<boolean>) => {
            state.isStoreCreated = action.payload;
        },
        RESET_IS_STORE_CREATED: (state, action) => {
            state.isStoreCreated = action.payload;
        },
        SAVE_LOGIN_USER_EMAIL: (state, action: PayloadAction<string>) => {
            state.loggedInUserEmail = action.payload;
        },
        SAVE_LOGIN_USER_TYPE: (state, action: PayloadAction<number>) => {
            state.loggedInUserType = action.payload;
        },
        IS_MAINTENANACE: (state, action: PayloadAction<string>) => {
            state.isMaintenance = action.payload;
        },
        SAVE_PER_PAGE_LIMIT: (state, action: PayloadAction<any>) => {
            state.perPageLimit = action.payload;
        },
        SAVE_OPEN_PROGRESSBAR:(state, action: PayloadAction<any>) => {
            state.isOpenProgressbar = action.payload;
        },
        SAVE_FILE_NAME:(state, action: PayloadAction<string>) => {
            state.isFileNameStored = action.payload;
        },
        PROGRESSBAR_MESSAGE:(state, action: PayloadAction<any>) => {
            state.isProgressbarMessage = action.payload;
        },
        IS_UPLOAD:(state, action: PayloadAction<any>) => {
            state.isUpload = action.payload;
        },
        IS:(state, action: PayloadAction<any>) => {
            state.cryptoMessage = action.payload;
        },
        NEW_PERMISSION: (state, action: PayloadAction<any>) => {
            state.newPermission = action.payload;
        },
        UNREGISTERED_IMAGE_STATUS: (state, action: PayloadAction<any>) => {
            state.unregisteredImageStatus = action.payload;
        } 
    }
});

export default commonSlice.reducer;
export const commonActions = commonSlice.actions;