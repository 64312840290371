import { API } from "../../../../config";
import AxiosUtils from "../../../../utils/AxiosUtils";
import Cookies from "../../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
import { LIST_LIMIT } from "../../../../constants/constants";
import PaginationModel from "../../../../models/Pagination/PaginationModel";
import CronJobModel from "../../../../models/CronJob/CronJobModel";


class systemConfiguration {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async getSystemConfiguration() {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.SYSTEM_CONFIGURATION).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SYSTEM_CONFIGURATION}`).getNew();
        let systemConfigurationData:CronJobModel = new CronJobModel(); 
        const systemConfigurationInfoList:CronJobModel[]=[];
        if(response?.data?.success == true)
        {   
            // response?.data?.data?.forEach((item: CronJobModel) => {
            let systemConfigurationInfo:CronJobModel = new CronJobModel();
            systemConfigurationInfo = CronJobModel.parseJson(response?.data?.data);
            systemConfigurationData=systemConfigurationInfo;
            // systemConfigurationInfoList.push(systemConfigurationInfo);
            // });
            return systemConfigurationData;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async updateSystemConfiguration() {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.SYSTEM_CONFIGURATION_UPDATE).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SYSTEM_CONFIGURATION_UPDATE}`, this.payload, this.headers).post();
        let systemUpdateMessage: string;
        if(response?.data?.success==true) {
            systemUpdateMessage = response?.data?.message;
            return systemUpdateMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }


   
}

export default systemConfiguration;

