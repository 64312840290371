import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, Typography, BottomNavigation, Snackbar, Alert, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { snackbarActions } from "../../../redux/features/snackbar/snackbarSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationMessage from "../Notifcation";
 
export default function LabelBottomNavigation(props:any) {
 
    const { isCookiePresent, isMsgRequired, msgVisibility } = props;
 
    if(process.env.REACT_APP_ENABLE_HTTPS == "true"){
 
        if (window.location.protocol == "http:") {
                 
            window.location.href =
                window.location.href.replace(
                    "http:", "https:");
        }
    }
 
   
    const [value, setValue] = React.useState("recents");
    // const[isMsgRequired , setIsMsgRequired] =useState<any>(false);
    const [t] = useTranslation();
    const {showLoader=false} = useAppSelector(state => state.loaderss.loaderInfo) ?? {};
    const [msg,setMsg]=useState("");
    const { show=false, message="", success=false, autoClose=true } = useAppSelector(state => state.snackbar.snackbarInfo) ?? {};
 
    useEffect(()=>{
        setMsg(message?message:"");
    },[message]);
   
    const dispatch = useAppDispatch();
 
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
 
    const handleCloseSnackBar = () => {
        dispatch(snackbarActions.HIDE_SNACKBAR(false));
    };

    const handleAutoCloseSnackBar = () => {
        // dispatch(snackbarActions.HIDE_SNACKBAR(false));
        if(msg){
            if(msg == message){
                dispatch(snackbarActions.HIDE_SNACKBAR(false));
            }
        }else{
            dispatch(snackbarActions.HIDE_SNACKBAR(false));
        }
    };
    // const handleCloseLoader = () => {
    //     dispatch(loaderActions.HIDE_LOADER(false));
    // };
 
    const today = new Date();
 
    return (
        <>
            {isMsgRequired && !isCookiePresent  ? <NotificationMessage isCookiePresent={isCookiePresent} isMsgRequired={isMsgRequired} msgVisibility={msgVisibility}/> : "" }
            <Box className="footer"
                component="div"
                // value={value}
                // onChange={handleChange}
            >
                <Grid>
                    <Typography
                        variant="inherit"
                        noWrap
                        component="div"
                        sx={{ display: { xs: "block", sm: "block" }, fontSize: 15 }}
                    >
                        {t("footer.copyright")} {today.getFullYear()} Cainz
                    </Typography>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={autoClose == false ? null : 5000}
                    // autoHideDuration={5000}
                    open={message ? show : false}
                    onClose={handleAutoCloseSnackBar}
                >
                    <Alert
                        onClose={handleCloseSnackBar}
                        severity={success ? "success" : "error"}
                    >
                        {message}
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showLoader ? true : false }            
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </>
    );
}