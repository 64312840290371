import { API } from "../../../config";
import CONSTANTS, { LIST_LIMIT } from "../../../constants/constants";
import { PaymentTableLogWithPaginationType } from "../../../interfaces/paymentTableInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class PaymentTableService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async paymentList(
        page: number,
        limit = LIST_LIMIT.ENCRYPTION_DECRYPTION_LIMIT,
        search:string,
        colName?:string,
        order?:string          
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PAYMENT_TABLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PAYMENT_TABLE}?page=${page}&limit=${limit}&search=${search}&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data;
            const paymentPaginationData: PaymentTableLogWithPaginationType = {
                paymentTableLogs: data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return paymentPaginationData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadPaymentList(
        search:string,
        colName?:string,
        order?:string   
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PAYMENT_TABLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PAYMENT_TABLE}?&search=${search}&column=${colName}&order=${order}&download=true`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data;
            const paymentPaginationData: PaymentTableLogWithPaginationType = {
                paymentTableLogs: data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return paymentPaginationData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }


    async downloadPaymentTableHeader()
    {
        const response = this.isHitDummyURL ? await new AxiosUtils(`${mockAPIPath.PAYMENT_TABLE_HEADER}`,  this.payload ).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.PAYMENT_TABLE_HEADER}?`,  this.payload, this.headers ).get(); 
           
        if (response?.data?.success) {
            // const { data } = response.data;
            return response.data;
           
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}

export default PaymentTableService;