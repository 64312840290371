import { useAppSelector } from "../redux/hooks";
import { ROUTES } from "../constants/routes";
import CONSTANTS from "../constants/constants";
import { permissionCategory } from "../constants/permissionCategory";
import { permissionInitialValue } from "../redux/features/roleBasedPermission/permissionSlice";

export const ProtectedRoute = ({ children, category }: any) => {

    const permissions = useAppSelector(state => state.permission.permissionDetails) ?? permissionInitialValue.permissionDetails;

    const { userType, modules } = permissions;

    if(userType == CONSTANTS.USER_TYPE_SUPER_ADMIN){
        return children;
    } else if(userType == CONSTANTS.USER_TYPE_ADMIN) {
        if(modules?.admin){
            let count = 0;
            for(let i = 0; i < modules?.admin?.length; i++){
                if(category == modules?.admin[i].key && modules?.admin[i]?.permissions?.view || category == permissionCategory.COMMON){
                    count++;
                    return children;
                }
            }
            if( count == 0 ){
                return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
                    
            }
        }
    } else {
        return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
    }
}; 