import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type InitialState = {
    snackbarInfo : {
        show?: boolean,
        message?: string,
        success?: boolean,
        autoClose?:boolean

    }
};

const initialState: InitialState = {
    snackbarInfo: {
        show: false,
        message: "",
        success: false,
        autoClose:true

    }
};

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        SHOW_SNACKBAR: (state, action: PayloadAction<object>) => {
            state.snackbarInfo = action.payload;
        },
        HIDE_SNACKBAR: (state, action: PayloadAction<boolean>) => {
            state.snackbarInfo.show = action.payload;
        }
    }
});

export default snackbarSlice.reducer;
export const snackbarActions = snackbarSlice.actions;
export const snackbarInitialValue : InitialState = initialState;