import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    IconButton,
    InputAdornment,
    Popover,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import PasswordService from "../service/PasswordService";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";

const UpdatePassword = (props: any) => {

    const { showSnackbar ,handleLoader} = props;


    {
    /* Language translate function*/
    }
    const [t, i18n] = useTranslation();
    {
    /* Language translate function*/
    }

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [error, setError] = useState({
        passwordError: "",
        confirmPasswordError: "",
    });

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");

    // validation
    const [, forceUpdate] = useState(0);

    const passwordRestrictionWord = CONSTANTS.RESTRICTED_KEYWORD;
    const isMatchedPasswordRestrictionWord = (password: string) => {
        const isMatched = passwordRestrictionWord.find((item) => {
            if (password.toLowerCase().includes(item.toLowerCase())) {
                return true;
            }
        });
        if (isMatched) {
            return isMatched;
        }
        return false;
    };
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                in: t("update_password.password_does_not_match"),
                regex: t("update_password.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                cainz: {
                    message: t("required.the_password_can_not_contain"),
                    rule: (val: string) => {
                        return !isMatchedPasswordRestrictionWord(val);
                    },
                },
            },
        })
    );
   
    useEffect(() => {
        forceUpdate(1);
    });
    //validation

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

  
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "password" ? setPassword(value) : setConfirmPassword(value);
        validator.current.hideMessageFor(name);
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { newPassword: password, confirmPassword };
            const isHitDummyURL = false;
            new PasswordService(payload, isHitDummyURL)
                .update()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    navigate(ROUTES.INITIAL_PAGE);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    const passSuggestion = (e: any) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickShowPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };

    const handleMouseDownPassword = () => {
        setPasswordType("text");
    };

    const handleClickShowConfirmPassword = () => {
        // setConfirmPasswordType("password");
        confirmPasswordType=="password" ? setConfirmPasswordType("text") : setConfirmPasswordType("password");
    };

    const handleMouseDownConfirmPassword = () => {
        setConfirmPasswordType("text");
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div className="main">
                {/* Create password page starts here */}
                <Container>
                    <Grid container  sx={{ py: 10 }}>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                           
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <Box sx={{ textAlign: "center", my: "1rem" }}>
                                <Typography variant="h6">
                                    {t("update_password.update_password")}{" "}
                                </Typography>
                            </Box>

                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownConfirmPassword}
                                                    // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { passwordType=="password" ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    type={passwordType}
                                    name="password"
                                    value={password}
                                    label={t("update_password.new_password")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    error={error.passwordError ? true : false}
                                    helperText={error.passwordError}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("password", password, [
                                    "required",
                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}
                            </Grid>
                            <Grid sx={{ mb:1 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownConfirmPassword}
                                                    // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleClickShowConfirmPassword}
                                                    // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { confirmPasswordType=="password" ? 
                                                        <VisibilityIcon /> : 
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={confirmPasswordType}
                                    fullWidth
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    label={t("update_password.confirm_password")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    error={error.confirmPasswordError ? true : false}
                                    helperText={error.confirmPasswordError}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message(
                                    "confirmPassword",
                                    confirmPassword,
                                    [
                                        "required",
                                        CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                        { in: password },
                                        { regex: CONSTANTS.PASSWORD_REGEX },
                                    ]
                                )}
                            </Grid>

                            <Typography sx={{ my:1, width: "auto", color:"#201d1d94"}}>
                                {t("create_password.password_suggestion")}
                            </Typography>

                            <Grid sx={{ mb: 2 }}>
                                <Button fullWidth onClick={handleSubmit}>
                                    {t("update_password.submit")}
                                </Button>
                            </Grid>
                        </Grid>

                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                </Container>
            </div>

            {/* Create password page ends here */}
        </>
    );
};

export default UpdatePassword;
