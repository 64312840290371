import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import { ROUTES } from "../../constants/routes";
import CONSTANTS, { ROLE_MANAGEMENT_SYSTEM } from "../../constants/constants";
import { permissionCategory } from "../../constants/permissionCategory";
import { permissionInitialValue } from "../../redux/features/roleBasedPermission/permissionSlice";

const CommonButton = (props:any) =>{
    
    const {functionToExecute, route, className, btnName, disableCondition, startIconRequired, sx}=props;

    const userType = useAppSelector((state) => state.common.loggedInUserType) ?? null;

    const permission_list = useAppSelector(
        (state) => state.permission.permissionDetails.modules?.admin
    ) ?? permissionInitialValue.permissionDetails.modules?.admin;

    const [isPermissionallowed, setIsPermissionAllowed] = useState<boolean>(true);

    /**
 * @description -   setting isPermissionAllowed state to true if current route has executive permission
 */
    useEffect(() => {
        const checkPermission = () => {
          
            if (route == ROUTES.INITIAL_PAGE) {
                const storePermission = permission_list?.find((item:any) => item.key == permissionCategory.STORE_MANAGEMENT);
                setIsPermissionAllowed(storePermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else if (route == ROUTES.ADMIN_USERS) {
                const userManagementPermission = permission_list?.find((item:any) => item.key == permissionCategory.ADMIN_USER_MANAGEMENT);
                setIsPermissionAllowed(userManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else if (route == ROUTES.UNREGISTERED_IMAGES) {
                const unregisteredImagesPermission = permission_list?.find((item:any) => item.key == permissionCategory.PRODUCT_MANAGEMENT);
                setIsPermissionAllowed(unregisteredImagesPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else {
                setIsPermissionAllowed(true);
            }
        };

        if(userType == CONSTANTS.USER_TYPE_ADMIN){
            checkPermission();
        }
    }, [route,permission_list]);



    return(

        <>
            <Button
                className={className}
                variant="contained"
                disabled={!isPermissionallowed ? !isPermissionallowed : disableCondition}
                startIcon={startIconRequired?startIconRequired:""}
                sx={sx ? sx : {textTransform: "capitalize"}}
                onClick={functionToExecute}
            >
                {btnName}
            </Button>            
        </>
        

    );

};
export default CommonButton;