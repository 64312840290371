import { useAppSelector } from "../redux/hooks";
import { permissionCategory } from "../constants/permissionCategory";
import CONSTANTS from "../constants/constants";
import { permissionInitialValue } from "../redux/features/roleBasedPermission/permissionSlice";

export const ProtectedMenu = ({ children, category }: any) => {
    
    const permissions = useAppSelector(state => state.permission.permissionDetails) ?? permissionInitialValue.permissionDetails;
    
    const { userType, modules } = permissions;

    if(userType == CONSTANTS.USER_TYPE_SUPER_ADMIN) {
        return children;
    }
    
    if (category != permissionCategory.SUPER_ADMIN || userType == CONSTANTS.USER_TYPE_ADMIN) {
        if (modules?.admin) {
            let count = 0;
            for (let i = 0; i < modules?.admin?.length; i++) {
                if (category == modules?.admin[i].key && modules?.admin[i]?.permissions?.view) {
                    count++;
                    return children;
                }
            }
            if (count == 0) {
                return null;

            }
        }
    }
};