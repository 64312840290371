import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    IconButton,
    TextField,
    CircularProgress,
    Typography,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../common/PageTitle";
import MUIDataTable from "mui-datatables";
import Pagination from "../../common/Pagination";
import Skeleton from "../../common/Skeleton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SearchIcon from "@mui/icons-material/Search";
import CONSTANTS, {
    LIST_LIMIT,
    SHEET_NAME,
} from "../../../constants/constants";
import PayeeTableService from "../service/payeeInfo";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import { useAppSelector } from "../../../redux/hooks";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import { Crypt } from "../../../utils/Crypt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ENC_DEC_CONFIG from "../config/config";

const OrderTable = (props: any) => {
    const { showSnackbar, handleLoader, 
        setPayeeInfoTableComp,
        setSelectAction, setSelectDataTable,
    } = props;
    const [errorFlag, setErrorFlag] = useState(-1);
    const crypt = new Crypt(setErrorFlag);
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [limit, setLimit] = useState<any>(
        !perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit
    );
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [decryptAndEncrypt, setdecryptAndEncrypt] = useState<any>(false);
    const [orderIdSearch, setOrderIdSearch] = useState<string>("");
    const [sellerCodeSearch, setSellerCodeSearch] = useState<string>("");
    const [statusFilter,setStatusFilter]=useState<any>("");
    const [isSellerCodeSearchChange, setIsSellerCodeSearchChange] =  useState<boolean>(false);
    const [isSearchChange, setIsSearchChange] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isInitialValue, setisInitialValue] = useState<boolean>(true);
    const [colFilter,setColFilter] =useState<string>("");
    const [resetData, setResetData] = useState(false);
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");
    const isUpload = useAppSelector((state) => state.common.isUpload) ?? true;
    const apiData: any[][] = [];
    const { t, i18n } = useTranslation();
    const [, forceUpdate] = useState(0);

    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setOrderIdSearch(value);
    };

    const onsearchApicall = () => {
        if (isSearchChange && !resetData) {
            apiResponseData(firstPage, limit);
        }
    };
    const propItem = [onsearchApicall, orderIdSearch];
    lazyTypingUtils(propItem);

    const handleSellerCodeSearch = (e: any) => {
        setIsSellerCodeSearchChange(true);
        const { value } = e.target;
        setSellerCodeSearch(value);
    };
    
    const sellerCodeSearchApicall = () => {
        if (isSellerCodeSearchChange && !resetData) {
            apiResponseData(firstPage, limit);
        }
    };
    const sellerCodePropItem = [sellerCodeSearchApicall, sellerCodeSearch];
    lazyTypingUtils(sellerCodePropItem);

    useEffect(() => {
        isUpload ? apiResponseData(currentPage, limit) : localStorageData();
    }, []);

    /**
        * @param - currentPage - this will store current page value 
        * @param - limit - this will store the limit for diplaying data 
        * @returns OMS order data
        * @description - this function returns data for all orders in the systems 
   */

    const apiResponseData = (currentPage: number, limit: number) => {
        setResetData(false);
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new PayeeTableService(payload, isHitDummyURL)
            .payeetList(currentPage, limit, orderIdSearch, sellerCodeSearch ,colFilter,direction,statusFilter)
            .then((res: any) => {
                setPaymentData(res?.payeeInfoLogs?.orderData);
                handleLoader(false);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.payeeInfoLogs?.totalCount);
                setTotalPages(res?.payeeInfoLogs?.totalPages); 
                const encryptArray =  new Array(paymentData).fill(false);
                setdecryptAndEncrypt(encryptArray);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };

    /**
       * @description - this function stores data to local storage 
         *for payment table and order table
    */
    const localStorageData = () => {
        const orderDataLocal: any = localStorage.getItem("orderTable");
        const uploadedData: any = JSON.parse(orderDataLocal) ?? [];
        if (uploadedData.length != 0) {
            setPaymentData(uploadedData);
        }
        handleLoader(false);
        setSkeleton(false);
        const encryptArray =  new Array(paymentData).fill(false);
        if (uploadedData.length != 0) {
            uploadedData.map((item: any, index: number) => {
                const iv = `${item?.orderId}`;
                encryptArray[index] = crypt.checkDecryptWithCryptoJS(item?.ordererEmail, iv, index, isInitialValue);
            });
        }
        setdecryptAndEncrypt(encryptArray);
    };

    /**
       * @description - this function contains functionality for normal download and db download 
    */
    const downloadPayeeTableData = () =>{
        isUpload ? downloadFromApi() : downloadFromLocal();
    };
 
    /**
     * @description - this function contains functionality for normal download and db download 
         downloadFromApi = download from DB
        downloadFromLocal = download from local. Meaning user can download file, uploaded by user themself.
    */

    const downloadFromApi = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        new PayeeTableService(payload, isHitDummyURL)
            .downloadPayeetList(orderIdSearch, sellerCodeSearch,colFilter,direction,statusFilter)
            .then((res: any) => {
                handleOnExport(res?.payeeInfoLogs?.orderData);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setLoading(false);
            });
    };

    useEffect(()=>{
        if(statusFilter|| resetData){
            apiResponseData(firstPage, limit);
        }
    },[statusFilter,resetData]);

    const downloadFromLocal = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const orderDataLocal: any = localStorage.getItem("orderTable");
        const uploadedData: any = JSON.parse(orderDataLocal) ?? [];
        let orderData:any;
        if (uploadedData.length !=0) {
            orderData = uploadedData;
        }
        handleOnExport(paymentData);
    };

    /**
     * @param downloadArray contains array data from payment table
     * @param item to itterate array data
     * @returns all payment data 
     * @description handleOnExport function maps data as {key:value} for api and local Data download.
     */

    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item: any, index: any) => {
            const iv = `${item?.orderId}`;
            downloadData.push({
                "id":item?.id,
                "seller_code": item?.sellerCode,
                "seller_name": item?.sellerName,
                "order_date": item?.orderDate,
                "order_confirmation_date": item?.orderConfirmationDate,
                "order_id": `${item?.orderId}`,
                "status":item?.status,
                "cancellation_datetime": item?.cancellationDateTime,
                "cancel_by": item?.cancelBy,
                "cancellation_reason_id": item?.cancellationReasonId,
                "payment_method_id": item?.paymentMethodId,
                "payment_method_name": item?.paymentMethodName,
                "arrival_date_designation": item?.arrivalDateDesignation,
                "desired_delivery_day": item?.desiredDeliveryDay,
                "desired_delivery_time": item?.desiredDeliveryTime,
                "delivery_date": item?.deliveryDate,
                "delivery_box_flag": item?.deliveryBoxFlag,
                "gift_flag": item?.giftFlag,
                "shipment_completion_date": item?.shipmentCompletionDate,
                "delivery_note_print_date": item?.deliveryNotePrintDate,
                "delivery_note_number": item?.deliveryNoteNumber,
                "total_product_price_regular": item?.totalProductPriceRegular,
                "total_product_price_reduced": item?.totalProductPriceReduced,
                "total_product_price_non_taxable": item?.totalProductPriceNonTaxable,
                "total_product_price": item?.totalProductPrice,
                "consumption_tax_amount_regular": item?.consumptionTaxAmountRegular,
                "consumption_tax": item?.consumptionTax,
                "consumption_tax_amount": item?.consumptionTaxAmount,
                "total_shipping_price": item?.totalShippingPrice,
                "total_order_amount": item?.totalOrderAmount,
                "total_point_awarded": item?.totalPointAwarded,
                "point_usage_amount": item?.pointUsageAmount,
                "orderer_email":decryptAndEncrypt[index] ? decryptedValue(item?.ordererEmail, iv, index, item?.isUpload) : encryptedValue(item?.ordererEmail, iv, index, item?.isUpload),
                "orderer_name": decryptAndEncrypt[index]?decryptedValue(item?.ordererName, iv, index, item?.isUpload) : encryptedValue(item?.ordererName, iv, index, item?.isUpload),
                "name_of_orderer": decryptAndEncrypt[index]?decryptedValue(item?.nameOfOrderer, iv, index, item?.isUpload) :encryptedValue(item?.nameOfOrderer, iv, index, item?.isUpload),
                "orderer_kana_sei":decryptAndEncrypt[index]?decryptedValue(item?.ordererKanaSei, iv, index, item?.isUpload) : encryptedValue(item?.ordererKanaSei, iv, index, item?.isUpload),
                "orderer_kana_mei":decryptAndEncrypt[index]?decryptedValue(item?.ordererKanaMei, iv, index, item?.isUpload) : encryptedValue(item?.ordererKanaMei, iv, index, item?.isUpload), 
                "orderer_postal_code":decryptAndEncrypt[index]?decryptedValue(item?.ordererPostalCode, iv, index, item?.isUpload) : encryptedValue(item?.ordererPostalCode, iv, index, item?.isUpload),
                "orderer_prefecture":decryptAndEncrypt[index]?decryptedValue(item?.ordererPrefecture, iv, index, item?.isUpload) : encryptedValue(item?.ordererPrefecture, iv, index, item?.isUpload),
                "orderer_address_1": decryptAndEncrypt[index]?decryptedValue(item?.ordererAddress1, iv, index, item?.isUpload) : encryptedValue(item?.ordererAddress1, iv, index, item?.isUpload),
                "orderer_address_2":decryptAndEncrypt[index]?decryptedValue(item?.ordererAddress2, iv, index, item?.isUpload) : encryptedValue(item?.ordererAddress2, iv, index, item?.isUpload),
                "orderer_address_3":decryptAndEncrypt[index]?decryptedValue(item?.ordererAddress3, iv, index, item?.isUpload) : encryptedValue(item?.ordererAddress3, iv, index, item?.isUpload),
                "orderer_company_name": decryptAndEncrypt[index]?decryptedValue(item?.ordererCompanyName, iv, index, item?.isUpload) : encryptedValue(item?.ordererCompanyName, iv, index, item?.isUpload),
                "orderer_department_name":decryptAndEncrypt[index]?decryptedValue(item?.ordererDepartmentName, iv, index, item?.isUpload) : encryptedValue(item?.ordererDepartmentName, iv, index, item?.isUpload),
                "orderer_phone_number":decryptAndEncrypt[index]?decryptedValue(item?.ordererPhoneNumber, iv, index, item?.isUpload) : encryptedValue(item?.ordererPhoneNumber, iv, index, item?.isUpload),
                "shipping_surname":decryptAndEncrypt[index]?decryptedValue(item?.shippingSurname, iv, index, item?.isUpload) : encryptedValue(item?.shippingSurname, iv, index, item?.isUpload),
                "shipping_first_name":decryptAndEncrypt[index]?decryptedValue(item?.shippingFirstName, iv, index, item?.isUpload) : encryptedValue(item?.shippingFirstName, iv, index, item?.isUpload),
                "shipping_address_kana_sei": decryptAndEncrypt[index]?decryptedValue(item?.shippingAddressKanaSei, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddressKanaSei, iv, index, item?.isUpload),
                "shipping_address_kana_mei": decryptAndEncrypt[index]?decryptedValue(item?.shippingAddressKanaMei, iv, index, item?.isUpload) :encryptedValue(item?.shippingAddressKanaMei, iv, index, item?.isUpload),
                "shipping_postal_code":decryptAndEncrypt[index]?decryptedValue(item?.shippingPostalCode, iv, index, item?.isUpload) : encryptedValue(item?.shippingPostalCode, iv, index, item?.isUpload),
                "shipping_prefecture": decryptAndEncrypt[index]?decryptedValue(item?.shippingPrefecture, iv, index, item?.isUpload) : encryptedValue(item?.shippingPrefecture, iv, index, item?.isUpload),
                "shipping_address_1":decryptAndEncrypt[index]?decryptedValue(item?.shippingAddress1, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddress1, iv, index, item?.isUpload),
                "shipping_address_2": decryptAndEncrypt[index]?decryptedValue(item?.shippingAddress2, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddress2, iv, index, item?.isUpload),
                "shipping_address_3": decryptAndEncrypt[index]?decryptedValue(item?.shippingAddress3, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddress3, iv, index, item?.isUpload),
                "shipping_company_name":decryptAndEncrypt[index]?decryptedValue(item?.shippingCompanyName, iv, index, item?.isUpload) : encryptedValue(item?.shippingCompanyName, iv, index, item?.isUpload),
                "shipping_department_name":decryptAndEncrypt[index]?decryptedValue(item?.shippingDepartmentName, iv, index, item?.isUpload) : encryptedValue(item?.shippingDepartmentName, iv, index, item?.isUpload),
                "shipping_phone_number": decryptAndEncrypt[index]?decryptedValue(item?.shippingPhoneNumber, iv, index, item?.isUpload) : encryptedValue(item?.shippingPhoneNumber, iv, index, item?.isUpload),
                "seller_memo":item?.sellerMemo,
                "seller_cancelable_flag": item?.sellerCancelableFlag,
                "oms_integration_status": item?.omsIntegrationStatus,
                "oms_linkage_date":item?.omsLinkageDate,
                "created_at":item?.createdAt,
                "created_by":item?.createdBy,
                "updated_at":item?.updatedAt,
                "updated_by":item?.updatedBy,
                "created_at_external":item?.createdAtExternal,
                "created_by_external":item?.createdByExternal,
                "updated_at_external":item?.updatedAtExternal,
                "updated_by_external":item?.updatedByExternal
            });
        });
        const fileName = SHEET_NAME.ORDER_DATA;
        downloadDataToExcel(downloadData, fileName, SHEET_NAME.ORDER_DATA);
        setLoading(false);
    };
    
    useEffect(()=>{
        if(isUpload){
            if(colFilter||direction){
                apiResponseData(currentPage, limit);
            }
        }      
    },[colFilter, direction]);

    const handleSorting =(changedColumn:any,key:any)=>{
        let sortingOrder="asc";
        if(colName != changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
            sortingOrder = direction=="asc"? "desc":"asc";
        }       
        setColName(changedColumn);   
        const columnName = changedColumn == "Order id" || changedColumn=="オーダーID" ? "order_id": changedColumn == "id" ? "id":changedColumn == "Seller name" || changedColumn=="出品者名" ? "seller_name":changedColumn == "Seller code" || changedColumn=="出品者コード" ? "seller_code":changedColumn == "Order date" || changedColumn == "注文日時" ? "order_date":"";
        setColFilter(columnName);
        if(!isUpload){
            setSkeleton(true);
            let sortedArray=[];
            if(key == ENC_DEC_CONFIG.SELLER_NAME || key == ENC_DEC_CONFIG.SELLER_CODE ){
                sortingOrder=="asc"?
                    sortedArray = [...paymentData].sort((a, b) => {
                        if (a[key]?.toLowerCase() != b[key]?.toLowerCase()) {
                            if(a[key]?.toLowerCase() < b[key]?.toLowerCase()){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }  else if(a.orderDate != b.orderDate) {
                            if(a.orderDate < b.orderDate){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else{
                            if(a.orderId < b.orderId){
                                return(-1);
                            }else{
                                return 1;
                            }

                        }
                    }):
                    sortedArray =[...paymentData].sort((a, b) => {
                        if (a[key]?.toLowerCase() != b[key]?.toLowerCase()) {
                            if(a[key]?.toLowerCase() > b[key]?.toLowerCase()){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }  else if(a.orderDate != b.orderDate) {
                            if(a.orderDate > b.orderDate){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else{
                            if(a.orderId > b.orderId){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }
                    });
            }else{
                sortingOrder=="asc"?
                    sortedArray = [...paymentData].sort((a, b) => {
                        if (a[key] != b[key]) {
                            if(a[key] < b[key]){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }  else if(a.orderId != b.orderId) {
                            if(a.orderId < b.orderId){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else{
                            if(a.sellerCode < b.sellerCode){
                                return(-1);
                            }else{
                                return 1;
                            }

                        }
                    }):
                    sortedArray = [...paymentData].sort((a, b) => {
                        if (a[key] != b[key]) {
                            if(a[key] > b[key]){
                                return(-1);
                            }else{
                                return 1;
                            }
                        }  else if(a.orderId != b.orderId) {
                            if(a.orderId > b.orderId){
                                return(-1);
                            }else{
                                return 1;
                            }
                        } else{
                            if(a.sellerCode > b.sellerCode){
                                return(-1);
                            }else{
                                return 1;
                            }

                        }
                    });
            } 
            const encryptArray =  new Array(paymentData).fill(false);
            if (sortedArray.length != 0) {
                sortedArray.map((item: any, index: number) => {
                    const iv = `${item?.orderId}`;
                    encryptArray[index] = crypt.checkDecryptWithCryptoJS(item?.ordererEmail, iv, index, isInitialValue);
                });
            }
            setdecryptAndEncrypt(encryptArray);  
            setPaymentData(sortedArray);
            setTimeout(()=>{
                setSkeleton(false);
    
            },100);          
        }else{
            const encryptArray =  new Array(paymentData).fill(false);
            setdecryptAndEncrypt(encryptArray);
        }
    };

    const columns = [
        {
            name:"id",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Typography>id</Typography>     
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"id")} >
                                {colFilter!="id" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                        </Box>
                    );
                }, 
            },
        },
        {
            name:t("order_table.seller_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>
                                <Typography>{t("order_table.seller_code")}</Typography>     
                                <Typography>(seller_code)</Typography> 
                            </Box>
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"sellerCode")} >
                                {colFilter!="seller_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>      
                        </Box>
                    );
                },   
            },
        },
        {
            name:t("order_table.seller_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>
                                <Typography>{t("order_table.seller_name")}</Typography>     
                                <Typography>(seller_name)</Typography> 
                            </Box>  
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"sellerName")} >
                                {colFilter!="seller_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },
            },
        },

        {
            name: t("order_table.order_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>
                                <Typography>{t("order_table.order_date")}</Typography>     
                                <Typography>(order_date)</Typography> 
                            </Box>   
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"orderDate")} >
                                {colFilter!="order_date" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name: t("order_table.order_confirmation_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.order_confirmation_date")}</Typography>     
                            <Typography>(order_confirmation_date)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.order_id"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box sx={{display:"flex"}}>
                            <Box>
                                <Typography>{t("order_table.order_id")}</Typography>     
                                <Typography>(order_id)</Typography>  
                            </Box> 
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name,"orderId")} >
                                {colFilter!="order_id" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },
            },
        },

        {
            name: t("order_table.status"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.status")}</Typography>     
                            <Typography>(status)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.cancellation_datetime"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.cancellation_datetime")}</Typography>     
                            <Typography>(cancellation_datetime)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.cancel_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.cancel_by")}</Typography>     
                            <Typography>(cancel_by)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.cancellation_reason_id"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.cancellation_reason_id")}</Typography>     
                            <Typography>(cancellation_reason_id)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.payment_method_id"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.payment_method_id")}</Typography>     
                            <Typography>(payment_method_id)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.payment_method_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.payment_method_name")}</Typography>     
                            <Typography>(payment_method_name)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.arrival_date_designation"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.arrival_date_designation")}</Typography>     
                            <Typography>(arrival_date_designation)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.desired_delivery_day"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.desired_delivery_day")}</Typography>     
                            <Typography>(desired_delivery_day)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.desired_delivery_time"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.desired_delivery_time")}</Typography>     
                            <Typography>(desired_delivery_time)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.delivery_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.delivery_date")}</Typography>     
                            <Typography>(delivery_date)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.delivery_box_flag"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.delivery_box_flag")}</Typography>     
                            <Typography>(delivery_box_flag)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.gift_flag"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.gift_flag")}</Typography>     
                            <Typography>(gift_flag)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.shipment_completion_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipment_completion_date")}</Typography>     
                            <Typography>(shipment_completion_date)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.delivery_note_print_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.delivery_note_print_date")}</Typography>     
                            <Typography>(delivery_note_print_date)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.delivery_note_number"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.delivery_note_number")}</Typography>     
                            <Typography>(delivery_note_number)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.total_product_price_regular"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_product_price_regular")}</Typography>     
                            <Typography>(total_product_price_regular)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.total_product_price_reduced"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_product_price_reduced")}</Typography>     
                            <Typography>(total_product_price_reduced)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.total_product_price_non_taxable"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_product_price_non_taxable")}</Typography>     
                            <Typography>(total_product_price_non_taxable)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.total_product_price"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_product_price")}</Typography>     
                            <Typography>(total_product_price)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.consumption_tax_amount_regular"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.consumption_tax_amount_regular")}</Typography>     
                            <Typography>(consumption_tax_amount_regular)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.consumption_tax"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.consumption_tax")}</Typography>     
                            <Typography>(consumption_tax)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.consumption_tax_amount"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.consumption_tax_amount")}</Typography>     
                            <Typography>(consumption_tax_amount)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.total_shipping_price"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_shipping_price")}</Typography>     
                            <Typography>(total_shipping_price)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.total_order_amount"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_order_amount")}</Typography>     
                            <Typography>(total_order_amount)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.total_point_awarded"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.total_point_awarded")}</Typography>     
                            <Typography>(total_point_awarded)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.point_usage_amount"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.point_usage_amount")}</Typography>     
                            <Typography>(point_usage_amount)</Typography>     
                        </Box>
                    );
                },
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name:t("order_table.orderer_email"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_email")}</Typography>     
                            <Typography>(orderer_email)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.orderer_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_name")}</Typography>     
                            <Typography>(orderer_name)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.name_of_orderer"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.name_of_orderer")}</Typography>     
                            <Typography>(name_of_orderer)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.orderer_kana_sei"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_kana_sei")}</Typography>     
                            <Typography>(orderer_kana_sei)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.orderer_kana_mei"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_kana_mei")}</Typography>     
                            <Typography>(orderer_kana_mei)</Typography>     
                        </Box>
                    );
                },
            },
        },
        {
            name:t("order_table.orderer_postal_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_postal_code")}</Typography>     
                            <Typography>(orderer_postal_code)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_prefecture"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_prefecture")}</Typography>     
                            <Typography>(orderer_prefecture)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_address_1"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_address_1")}</Typography>     
                            <Typography>(orderer_address_1)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_address_2"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_address_2")}</Typography>     
                            <Typography>(orderer_address_2)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_address_3"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_address_3")}</Typography>     
                            <Typography>(orderer_address_3)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_company_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_company_name")}</Typography>     
                            <Typography>(orderer_company_name)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_department_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_department_name")}</Typography>     
                            <Typography>(orderer_department_name)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.orderer_phone_number"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.orderer_phone_number")}</Typography>     
                            <Typography>(orderer_phone_number)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_surname"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_surname")}</Typography>     
                            <Typography>(shipping_surname)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_first_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_first_name")}</Typography>     
                            <Typography>(shipping_first_name)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_address_kana_sei"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_address_kana_sei")}</Typography>     
                            <Typography>(shipping_address_kana_sei)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_address_kana_mei"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_address_kana_mei")}</Typography>     
                            <Typography>(shipping_address_kana_mei)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_postal_code"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_postal_code")}</Typography>     
                            <Typography>(shipping_postal_code)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_prefecture"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_prefecture")}</Typography>     
                            <Typography>(shipping_prefecture)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_address_1"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_address_1")}</Typography>     
                            <Typography>(shipping_address_1)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_address_2"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_address_2")}</Typography>     
                            <Typography>(shipping_address_2)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_address_3"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_address_3")}</Typography>     
                            <Typography>(shipping_address_3)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_company_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_company_name")}</Typography>     
                            <Typography>(shipping_company_name)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.shipping_department_name"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_department_name")}</Typography>     
                            <Typography>(shipping_department_name)</Typography>     
                        </Box>
                    );
                }
            },
        },

        {
            name:t("order_table.shipping_phone_number"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.shipping_phone_number")}</Typography>     
                            <Typography>(shipping_phone_number)</Typography>     
                        </Box>
                    );
                }
            },
        },

        {
            name:t("order_table.seller_memo"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.seller_memo")}</Typography>     
                            <Typography>(seller_memo)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.seller_cancelable_flag"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.seller_cancelable_flag")}</Typography>     
                            <Typography>(seller_cancelable_flag)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.oms_integration_status"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.oms_integration_status")}</Typography>     
                            <Typography>(oms_integration_status)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.oms_linkage_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.oms_linkage_date")}</Typography>     
                            <Typography>(oms_linkage_date)</Typography>     
                        </Box>
                    );
                }
            },
        },
     
        {
            name:t("order_table.created_at"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.created_at")}</Typography>     
                            <Typography>(created_at)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.created_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.created_by")}</Typography>     
                            <Typography>(created_by)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.updated_at"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.updated_at")}</Typography>     
                            <Typography>(updated_at)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.updated_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.updated_by")}</Typography>     
                            <Typography>(updated_by)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.updated_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.created_at_external")}</Typography>     
                            <Typography>(created_at_external)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.updated_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.created_by_external")}</Typography>     
                            <Typography>(created_by_external)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.updated_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.updated_at_external")}</Typography>     
                            <Typography>(updated_at_external)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name:t("order_table.updated_by"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        <Box>
                            <Typography>{t("order_table.updated_by_external")}</Typography>     
                            <Typography>(updated_by_external)</Typography>     
                        </Box>
                    );
                }
            },
        },
        {
            name: t("encryption_decryption.action"),
            options: {
                filter: true,
                sort: false,
            },
        },
    ];
    /**
     * @description this function use for UTF-8 error
     */
    
    useEffect(() => {
        if(errorFlag != -1){
            encryptFn(errorFlag);
            setErrorFlag(-1);
        }
    }, [errorFlag]);
    
    /**
     * @description decryptFn function use for decrypt data
     */
    
    const decryptFn = (index: any) => {
        const decryptId =  [...decryptAndEncrypt];
        decryptId[index]=true;
        setdecryptAndEncrypt(decryptId);
        setisInitialValue(false);
    };
 
    /**
     * @description encryptFn function use for encrypted data 
     */

    const encryptFn = (index: any) => {
        const encryptId =  [...decryptAndEncrypt];
        encryptId[index]=false;
        setdecryptAndEncrypt(encryptId);
        setisInitialValue(false);
    };

    const decryptedValue = (item:any, iv:any, index:number, upload:any) =>{
        if(upload){
            if(isInitialValue){
                return item;
            }
            return  crypt.checkDecryptWithCryptoJS(item, iv, index, isInitialValue) ? item : crypt.decryptWithCryptoJS(item, iv, index);
        } else {
            // Since DB contains only encrypted value so decrypt value form db
            return crypt.decryptWithCryptoJS(item, iv, index);   
        }
    };

    const encryptedValue = (item:any, iv:string, index:number, upload:any) =>{
        if(upload){
            if(isInitialValue){
                return item;
            }
            return crypt.checkDecryptWithCryptoJS(item, iv, index, isInitialValue) ? crypt.encryptWithCryptoJS(item, iv, index)  : item ;
            // return crypt.encryptWithCryptoJS(item, iv, index);   
        }else{
            // Since DB contains only encrypted value so return encrypted value form db
            return item;       
        }
    };

    /**
         * @description decryptAndEncrypt is a state this return true and false on bases index 
      */
    const decryptButton = (index: any) => {

        return (
            <>
                {
                    decryptAndEncrypt[index] ? (
                        <Box className="table_btn">
                            <Button
                                key={index}
                                onClick={() => encryptFn(index)}
                                className="table_btn"
                                variant="contained"
                                color="warning"
                            >
                                {t("encryption_decryption.erncrypt")}
                            </Button>
                        </Box>
                    ) : (
                        <Box className="table_btn">
                            <Button
                                key={index}
                                onClick={() => decryptFn(index)}
                                className="table_btn"
                                variant="contained"
                            >
                                {t("encryption_decryption.decrypt")}
                            </Button>
                        </Box>
                    )
                }
            </>
        );
    };

    const resetAllFilter = () =>{
        setStatusFilter("");
        setSellerCodeSearch("");
        setOrderIdSearch("");
        setResetData(true);
    }; 

    /**
      * @description tableAlignRight function use for table column show in right align
    */
    const tableAlignRight = (value: any) => {
        return (
            <Box className="text_right">{value}</Box>
        );
    };

    /**
     * @param paymentData contains array data from API
     * @param item to itterate array data
     * @param index to itterate array index
     * @returns all payment data 
     * @description apiTableData function contain api data and some fix item contains 
     * decryptAndEncrypt function use for decryption and Encryption.
     *  
     */ 
   
    const apiTableData = () => {
        {
            paymentData?.map((item: any, index: number) => {
                const iv = `${item?.orderId}`;
                apiData[index] = [];  
                apiData[index].push(
                    item?.id,
                    item?.sellerCode,
                    item?.sellerName,
                    item?.orderDate,
                    item?.orderConfirmationDate,
                    item?.orderId,
                    item?.status,
                    item?.cancellationDateTime,
                    item?.cancelBy,
                    item?.cancellationReasonId,
                    item?.paymentMethodId,
                    item?.paymentMethodName,
                    item?.arrivalDateDesignation,
                    item?.desiredDeliveryDay,
                    item?.desiredDeliveryTime,
                    item?.deliveryDate,
                    item?.deliveryBoxFlag,
                    item?.giftFlag,
                    item?.shipmentCompletionDate,
                    item?.deliveryNotePrintDate,
                    item?.deliveryNoteNumber,
                    tableAlignRight(item?.totalProductPriceRegular),
                    tableAlignRight(item?.totalProductPriceReduced),
                    tableAlignRight(item?.totalProductPriceNonTaxable),
                    tableAlignRight(item?.totalProductPrice),
                    tableAlignRight(item?.consumptionTaxAmountRegular),
                    tableAlignRight(item?.consumptionTax),
                    tableAlignRight(item?.consumptionTaxAmount),
                    tableAlignRight(item?.totalShippingPrice),
                    tableAlignRight(item?.totalOrderAmount),
                    tableAlignRight(item?.totalPointAwarded),
                    tableAlignRight(item?.pointUsageAmount),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererEmail, iv, index, item?.isUpload) : encryptedValue(item?.ordererEmail,iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererName, iv, index, item?.isUpload) : encryptedValue(item?.ordererName,  iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.nameOfOrderer, iv, index, item?.isUpload) : encryptedValue(item?.nameOfOrderer, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererKanaSei, iv, index, item?.isUpload) : encryptedValue(item?.ordererKanaSei, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererKanaMei, iv, index, item?.isUpload) : encryptedValue(item?.ordererKanaMei, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererPostalCode, iv, index, item?.isUpload) : encryptedValue(item?.ordererPostalCode, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererPrefecture, iv, index, item?.isUpload) : encryptedValue(item?.ordererPrefecture, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererAddress1, iv, index, item?.isUpload) : encryptedValue(item?.ordererAddress1, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererAddress2, iv,index, item?.isUpload) : encryptedValue(item?.ordererAddress2, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererAddress3, iv, index, item?.isUpload) : encryptedValue(item?.ordererAddress3, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererCompanyName, iv, index, item?.isUpload) : encryptedValue(item?.ordererCompanyName, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererDepartmentName, iv, index, item?.isUpload) : encryptedValue(item?.ordererDepartmentName, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.ordererPhoneNumber, iv, index, item?.isUpload) : encryptedValue(item?.ordererPhoneNumber, iv, index, item?.isUpload) ,
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingSurname, iv, index, item?.isUpload) : encryptedValue(item?.shippingSurname, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingFirstName, iv, index, item?.isUpload) : encryptedValue(item?.shippingFirstName, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingAddressKanaSei, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddressKanaSei, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingAddressKanaMei, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddressKanaMei, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingPostalCode, iv, index, item?.isUpload) : encryptedValue(item?.shippingPostalCode, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingPrefecture, iv, index, item?.isUpload) :encryptedValue(item?.shippingPrefecture, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingAddress1, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddress1, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingAddress2, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddress2, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingAddress3, iv, index, item?.isUpload) : encryptedValue(item?.shippingAddress3, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingCompanyName, iv, index, item?.isUpload) : encryptedValue(item?.shippingCompanyName, iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingDepartmentName, iv, index, item?.isUpload) : encryptedValue(item?.shippingDepartmentName,iv, index, item?.isUpload),
                    decryptAndEncrypt[index] ? decryptedValue(item?.shippingPhoneNumber, iv, index, item?.isUpload,) : encryptedValue(item?.shippingPhoneNumber, iv, index, item?.isUpload),
                    item?.sellerMemo, 
                    item?.sellerCancelableFlag,
                    item?.omsIntegrationStatus,
                    item?.omsLinkageDate,
                    item?.createdAt,
                    item?.createdBy,
                    item?.updatedAt,
                    item?.updatedBy,
                    item?.createdAtExternal,
                    item?.createdByExternal,
                    item?.updatedAtExternal,
                    item?.updatedByExternal,
                    decryptButton(index)
                );
            });
        }
    };
    apiTableData();

    useEffect(() => {
        const handlePageRefresh = () => {
            localStorage.removeItem("orderTable");
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);

    /**
     * @description HandleToggle function show FilterAltIcon and FilterAltOffIcon icon .
    */

    const HandleToggle = () => {
        setFilterToggle(!filterToggle);
    };

    const handleStatusFilter = (e:any)=>{
        const { value } = e.target;
        setStatusFilter(value);
    };

    /**
     * @description backToPrevious function reset all data .
    */
    const backToPrevious = () =>{
        setPayeeInfoTableComp(false);
        setSelectAction("");
        setSelectDataTable("");
        localStorage.removeItem("paymentTable");
        localStorage.removeItem("orderTable");
    };
  
    return (
        <>
            <PageTitle title={t("encryption_decryption.encryption_decryption_tools")} />
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className="btn_box">
                            <Box>
                                <Button variant="contained" onClick={backToPrevious}>
                                    {t("encryption_decryption.back")}
                                </Button>
                            </Box>

                            <Box>
                                {isUpload ?
                                    filterToggle ? (
                                        <FilterAltOffIcon
                                            className="filter_icon"
                                            onClick={HandleToggle}
                                        />
                                    ) : (
                                        <FilterAltIcon
                                            className="filter_icon"
                                            onClick={HandleToggle}
                                        />
                                    ):"" }

                                <Button
                                    className="margin_download_button"
                                    variant="contained"
                                    disabled={loading || paymentData.length == 0}
                                    sx={{ textTransform: "capitalize" }}
                                    onClick={downloadPayeeTableData}
                                >
                                    {t("pr.download")}
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginTop: "-12px",
                                                marginLeft: "-12px",
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <>
                <div className="main">
                    {skeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="10"
                            my={0}
                        />
                    ) : (
                        <>
                            {filterToggle && (
                                <Box className="filter_bg">
                                    <Container>
                                        <Grid container spacing={0}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                sx={{ py: 1, px: 2 }}
                                            >
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label={t("encryption_decryption.search_by_order_id")}
                                                    onChange={handleSearchChange}
                                                    sx={{backgroundColor:"#ffffff"}}
                                                    value={orderIdSearch}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                sx={{ py: 1, px: 2 }}
                                            >
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label={t("encryption_decryption.search_by_store_code")}
                                                    onChange={handleSellerCodeSearch}
                                                    sx={{backgroundColor:"#ffffff"}}
                                                    value={sellerCodeSearch}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                sx={{ py: 1, px: 2 }}
                                            >
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel>{t("encryption_decryption.status")}</InputLabel>
                                                    <Select
                                                        className="pastnum"
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        value={statusFilter}
                                                        input={
                                                            <OutlinedInput label={t("encryption_decryption.status")} />
                                                        }
                                                        onChange={handleStatusFilter}
                                                    >
                                                        <MenuItem value={3}>{t("encryption_decryption.backlog_of_orders")}</MenuItem>
                                                        <MenuItem value={5}>{t("encryption_decryption.preparation_for_shipping")}</MenuItem>
                                                        <MenuItem value={6}>{t("encryption_decryption.shipped")}</MenuItem>
                                                        <MenuItem value={9}>{t("encryption_decryption.canceled")}</MenuItem>
                                                    </Select>                                                    
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                <Button
                                                    variant="contained"                           
                                                    sx={{textTransform: "capitalize" , mt :2}}
                                                    onClick={resetAllFilter}> {t("pr.reset")}
                                                </Button>
                                            </Grid> 
                                        </Grid>
                                    </Container>
                                </Box>
                            )}
                            <Container sx={{ my: 4 }} className="payment_table first_table_cell_small_width">
                                <MUIDataTable
                                    title={""}
                                    columns={columns}
                                    data={apiData}
                                    options={{
                                        pagination: false,
                                        print: false,
                                        download: false,
                                        filter: false,
                                        viewColumns: false,
                                        search: false,
                                        selectableRowsHideCheckboxes: true,
                                        textLabels: {
                                            body: {
                                                noMatch: t("table_text.no_match").toString(),
                                            },
                                            viewColumns: {
                                                title: t("table_text.show_columns").toString(),
                                                titleAria: "Show/Hide Table Columns",
                                            },
                                            toolbar: {
                                                search: t("table_text.search").toString(),
                                                viewColumns: t(
                                                    "table_text.view_columns"
                                                ).toString(),
                                            },
                                        },
                                        searchPlaceholder: t(
                                            "table_text.search_placeholder"
                                        ).toString(),
                                        responsive: "standard",
                                    }}
                                />
                            </Container>
                        </>
                    )}
                </div>
            </>

            {isUpload && 
                    <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        pageChange={apiResponseData}
                        totalPage={totalPages}
                        rowsPerPage={limit}
                        rowsPerPageChange={setLimit}
                 
                    />
            }
        </>
    );
};

export default OrderTable;
