import React, { useEffect, useRef, useState } from "react";
import { DateTimePicker } from "@mui/lab";
import {
    Autocomplete,
    Container,
    Button,
    Grid,
    Typography,
    TextField,
    Box,
} from "@mui/material";
import PageTitle from "../PageTitle";
import { useTranslation } from "react-i18next";
import SystemConfigurationService from "./services/SystemConfigurationService";
import SimpleReactValidator from "simple-react-validator";
import e from "express";
import Skeleton from "../Skeleton";


const CronJob = (props:any) => {
    const {showSnackbar,handleLoader} = props;
    const dates = [
        { label: "01"},
        { label: "02"},
        { label: "03"},
        { label: "04"},
        { label: "05"},
        { label: "06"},
        { label: "07"},
        { label: "08"},
        { label: "09"},
        { label: "10"},
        { label: "11"},
        { label: "12"},
        { label: "13"},
        { label: "14"},
        { label: "15"},
        { label: "16"},
        { label: "17"},
        { label: "18"},
        { label: "19"},
        { label: "20"},
        { label: "21"},
        { label: "22"},
        { label: "23"},
        { label: "24"},
        { label: "25"},
        { label: "26"},
        { label: "27"},
        { label: "28"},
        
    ];
    const [settlementDate,setSettlementDate] = useState<any>(null);
    const [paymentCompletionDate,setPaymentCompletionDate] = useState<any>(null);
    // const [pointRedemption,setPointRedemption] = useState<any>(null);
    // const [salesCommisionRate,setSalesCommisionRate] = useState<any>(null);
   
    const [t, i18n] = useTranslation();
    const [, forceUpdate] = useState(0);
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                maxCharValidation: {
                    // message: t("registerpage.max_char",{size:100}).toString(),
                    message:"in valid",
                    rule: (value: any, params: any) => {
                        return value <= 100;
                    },
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    const systemConfiguration = () => {
        const payload = {};
        const isHitDummyURL = false;
        new SystemConfigurationService(payload, isHitDummyURL)
            .getSystemConfiguration()
            .then((res) => {
                setSettlementDate(res.settlementDate);
                setPaymentCompletionDate(res.paymentCompletionDate);
                // setPointRedemption(res?.pointRedemptionRate);
                // setSalesCommisionRate(res?.salesCommissionRate);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };
    const systemConfigurationUpdate=()=>{
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = {
                "settlementDate": settlementDate,
                "paymentCompletionDate":paymentCompletionDate,
                // "pointRedemptionRate" : pointRedemption,
                // "salesCommissionRate" : salesCommisionRate   
            };
            const isHitDummyURL = false;
            new SystemConfigurationService(payload, isHitDummyURL)
                .updateSystemConfiguration()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res,true);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(()=>{
        systemConfiguration();
    },[]);
    // const handleOnBlur = (e: any) => {
    //     const { name } = e.target;
    //     validator.current.showMessageFor(name);      
    //     let data=null;
    //     (name=="Sales commision rate" || name=="Point redemption rate") && e.target.value ? ( data = (parseFloat(e.target.value ? e.target.value : 0).toFixed(2)),
    //     data = data.replace(/^0*(?!$)(\d+)/, "$1"),
    //     (
    //         name==="Sales commision rate" ? setSalesCommisionRate(data): 
    //             name=="Point redemption rate" ? setPointRedemption(data)  : null
    //     )): null;
    // };
    const handleInputType = (e: any) => {
        // e.target.value = e.target.value.replace(/[^0-9]/g, "");
    };
    return (
        <>
            <div className="main">
                <PageTitle title={t("system_configuration.system_configuration")}/>
                <Container sx={{ mt: 4 }}>
                    {/* {settlementDate ?  */}
                    <><Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 1 }}>
                            {settlementDate==null ? <Skeleton count ={1} variant="rectangular" height={55} /> :   <Autocomplete
                                fullWidth
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={dates}
                                getOptionLabel={(option:any) => option.label}
                                value={ dates.find((v:any) => v.label == (settlementDate ? settlementDate : 1))}
                                onChange={(e, value:any, situation, option)=>setSettlementDate(value?.label)}  
                                renderInput={(params) => <TextField {...params} label={t("system_configuration.settlement_date")}  />}
                            /> 
                            } 
                            {/* {validator.current.message("company_name", settlementDate, [
                                "required",])} */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 1 }}>
                            {settlementDate==null ? <Skeleton count ={1} variant="rectangular" height={55} /> :   <Autocomplete
                                fullWidth
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={dates}
                                getOptionLabel={(option:any) => option.label}
                                value={ dates.find((v:any) => v.label == (paymentCompletionDate ? paymentCompletionDate : 1))}
                                onChange={(e, value:any, situation, option)=>setPaymentCompletionDate(value?.label)}  
                                renderInput={(params) => <TextField {...params} label={t("system_configuration.payment_completion_date")}  />}
                            /> 
                            } 
                            {/* {validator.current.message("company_name", paymentCompletionDate, [
                                "required",])} */}
                        </Grid>
                    </Grid>    
                        
                    
                    <Grid item lg={12} xs={12} sm={12} md={12}>
                        <Box className="table_left" sx={{ mt: 2 }}>
                            <Button onClick={() => systemConfigurationUpdate()} disabled={settlementDate ? false : true}>{t("system_configuration.update")}</Button>
                        </Box>
                    </Grid>
                    </>  
                    {/* : null } */}
                </Container>
            </div>
        </>
    );
};

export default CronJob;
