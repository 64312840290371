import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { Container } from "@mui/system";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const UnauthorizedAccess = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Container>
                <Grid container sx={{ py: 30 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box className="text_center">
                            <Typography variant="h1"><strong>403</strong></Typography>
                            <Typography variant="h4">{t("unauthorized_access.unauthorized_access")}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default UnauthorizedAccess;
