import React, { useEffect, useState } from "react";
import {  Box, Checkbox, Container, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import Skeleton from "../../common/Skeleton";


const ReceivingEmailPermission = (props: any) => {

    const {  permissionData,showSnackbar, skeleton ,setPayloadData,payloadData } = props;

    const[t,i18n] = useTranslation(); 
    const [allChecked, setAllChecked]=useState<boolean>(false);
    const [emailPermissionFinalData, setEmailPermissionFinalData]= useState<Array<object>>([]);
    const apiData: any[][] = [];

    /**
 *
 * @description - function to make EmailPermission Site JSON
 */

    useEffect(() => {
        let moduleData : { name: string ,key: string, value: any};
        const initialState = () => {
            const mappedPermissions = permissionData?.map((item :any) => {
                moduleData = {
                    ...(i18n.language == "jp" ? { name: item?.nameJPN } : { name: item?.name }),
                    key: item?.key,
                    value: item?.value,
                };
                return moduleData;
            });
            setEmailPermissionFinalData(mappedPermissions);
        };
        initialState();
    }, [permissionData,i18n.language]);

    useEffect(()=>{
        setPayloadData((pre:any)=>{
            const payloadd ={...pre};
            emailPermissionFinalData?.map((item: any, indexItem: number) => {
                if(item?.key == "notify_refund_email"){
                    payloadd.notifyRefundEmail=item?.value;
                } else if(item?.key== "notify_billing_email"){
                    payloadd.notifyBillingEmail=item?.value;
                } else if(item?.key== "notify_refund_update_email"){
                    payloadd.notifyRefundUpdateEmail=item?.value;
                } else {
                    payloadd.notifyPayeeInfoEmail=item?.value;
                }
                // delete payload.emailPermissions;
                // delete payload.userType;
            });
            return payloadd;
        });
        setAllChecked(emailPermissionFinalData?.every((item: any) => item.value));
    },[emailPermissionFinalData]);

    const columns = [
        {
            name: "Checkbox",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        checkedAllCheckbox()
                    );
                },     
            },
        },
        {
            name: t("role_management_system.s_no"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.email"),
            options: {
                filter: false,
                sort: false,
            },
        },      
    ];
    /**
 *
 * @param index - index of checkbox which is edit
 * @param field - field corresponding to that checkbox
 * @description - function to manage checkedItem state when any checkbox is edit
 */
    const handleCheckboxChange = (name:any,index:any,value:any,key:any) => {  
        setEmailPermissionFinalData((prevItems: any) => {
            const newItems = [...prevItems];
            newItems[index].value = newItems[index].value ^ 1;
            return newItems;
        });
    };

    /**
 *
 * @description - function to manage checkedItem state when all Checkbox is clicked 
 */

    const handleAllCheckbox = () => {
        const updatedData = emailPermissionFinalData?.map((item: any) => ({
            ...item,
            value: allChecked ? 0 : 1,
        }));
        setEmailPermissionFinalData(updatedData);
    };

    const checkedAllCheckbox = () =>{
        return(
            <>
                <Checkbox
                    checked={allChecked}
                    onChange={()=>handleAllCheckbox()}
                />
            </>
        );
    };

    /**
 *
 * @param index - index of checkbox which is edit
 * @description - function to show checkbox in table
 */
    const checkedCheckbox = (value:any,index:any,key:any,name:any) =>{
        return(
            <>
                <Checkbox
                    checked={value}
                    onChange={() => handleCheckboxChange(name,index,value,key)}
                />
            </>
        );
    };

    const apiTableData = () => {
        {emailPermissionFinalData?.map((item: any, indexItem: number) => {
            apiData[indexItem] = [];
            apiData[indexItem].push(
                checkedCheckbox(item?.value== 1 ? true : false, indexItem, item?.key, item?.value),
                indexItem+1,
                item?.name,  
            );
        });
        }
    };
    apiTableData();

    return (

        <>                      
            <Box className="AdministrativeSiteBox">
                <Typography className="administrativeSiteTitle">{t("role_management_system.permission_to_receive_email")}</Typography>
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="4"
                        my={0}
                    />
                ) : (
                    <Container className="minimum_column  receivingEmailPermissionTable createUserRoleTable">
                        <MUIDataTable 
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                viewColumns:false,
                                selectableRowsHideCheckboxes: true,                              
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    viewColumns: {
                                        title: t("table_text.show_columns").toString(),
                                        titleAria: "Show/Hide Table Columns",
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",                                       
                            }}                                  
                        />
                    </Container> 
                )}                         
            </Box>                                                 
        </>
    );
};

export default ReceivingEmailPermission;