import React, { useEffect, useRef, useState } from "react";
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../common/PageTitle";
import Skeleton from "../common/Skeleton";
import MUIDataTable from "mui-datatables";
import CONSTANTS, { LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import Pagination from "../common/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import SimpleReactValidator from "simple-react-validator";
import ActivityLogService from "./service/ActivityLogService";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import { useAppSelector } from "../../redux/hooks";
import Utility from "../../utils/Utility";

const ActivityLog = (props: any) => {
    const { showSnackbar } = props;

    const [, forceUpdate] = useState(0);
    const firstPage = 1;
    const apiData: any[][] = [];
    const [t, i18n] = useTranslation();
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const utility = new Utility;
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.ACTIVITY_LOG_LIST_LIMIT : perPageLimit );
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [activityLogs, setActivityLogs]= useState<any>();
    const [valueFrom, setValueFrom] = useState<any>(null);
    const [valueTo, setValueTo] = useState<any>(null);
    const [search, setSearch] = useState<string>("");
    const [screenName, setScreenName] = useState<string>("");
    const [toParam,setToParam] = useState<string>("");
    const [fromParam,setFromParam] = useState<string>("");
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [resetData, setResetData] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [screenNameFilterOptions, setScreenNameFilterOptions] =useState<any>();

    const columns = [
        {
            name: t("activity_log.s_no"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("activity_log.date_time"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("activity_log.user_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("activity_log.screen_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
    
        {
            name: t("activity_log.action_name"),
            options: {
                filter: true,
                sort: false,
            },
        },
    ];

    useEffect(() => {
        forceUpdate(1);
    });

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(()=>{
        activityLogList(firstPage, limit,true);

    },[]);

    useEffect(()=>{
        if(screenName || resetData){
            activityLogList(firstPage, limit);
        }

    },[screenName,resetData]);

    const apiTableData = () => {
        activityLogs?.map(
            (item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    item?.createdAt,
                    item?.userName,
                    item?.screenName,
                    item?.actionName
                    
                );
            }
        );
    };
    apiTableData();

    const activityLogList = (currentPage: number, limit: any ,isPageRendered=false) => {
        setResetData(false);    
        setSkeleton(true);
        setIsSearchChange(false);
        const payload = {};
        const isHitDummyURL = false;
        new ActivityLogService(payload, isHitDummyURL)
            .getActivityLog(currentPage, limit,screenName,search,toParam,fromParam)
            .then((res) => {
                if(isPageRendered){
                    setScreenNameFilterOptions(res?.screenNames);
                }
                setCurrentPage(currentPage);
                setLimit(limit);
                setActivityLogs(res?.activityLogs);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
        document.querySelector(".from-date-seller-picker input")?.setAttribute("disabled","true");
        document.querySelector(".to-date-seller-picker input")?.setAttribute("disabled","true");
    };

    const downloadActivityLog = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        new ActivityLogService(payload, isHitDummyURL)
            .downloadActivityLogList(screenName,search,toParam,fromParam)
            .then((res: any) => {
                handleOnExport(res?.activityLogs);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setLoading(false);
                setSkeleton(false);
            });
    };

    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    const onsearchApicall = () =>{
        if(isSearchChange){
            activityLogList(firstPage, limit);
        } 
    };

    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);


    const handleScreenNameChange = (event: SelectChangeEvent) => {
        setScreenName(event.target.value);
    };

    const handleDateFrom = (value:any) =>{
        const {$D, $M, $y} = value;
        let month = $M+1;
        let dateto = $D;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        setFromParam(`${$y}/${month}/${dateto}`);
        setValueFrom(value);     
        // if(value?.$d=="Invalid Date"){
        //     console.log(value,"Invalid value");
        // }else{
        //     setValueFrom(value);
        // }
    };

    const handleDateTo = (value:any) =>{
        const {$D, $M, $y} = value;
        let month = $M+1;
        let dateto = $D;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        setToParam(`${$y}/${month}/${dateto}`);
        setValueTo(value);
    };

    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };

    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    const resetAllFilter = () =>{
        setSearch("");
        setValueTo(null);
        setValueFrom(null);
        setFromParam("");
        setToParam("");
        setResetData(true); 
        setScreenName("");   
    };

    const handleSubmit = () =>{
        if (validator.current.allValid()) {
            activityLogList(firstPage, limit);
        }
        else {
            validator.current.showMessages();
        }
    };


    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item :any)=>{
            downloadData.push({
                "日時":item?.createdAt?.split("T")[0],
                "ユーザー名":item?.userName,
                "画面名":item?.screenName,
                "アクション":item?.actionName,  
                "メッセージ":item?.message 
            });
        });
        const fileName=`log_${utility.getCurrentTimestamp()}.xlsx`;
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.ACTIVITY_LOG);
        setLoading(false);

    };

    return (
        <>
            <PageTitle title={t("activity_log.activity_log")} />

            <Container sx={{ my:4 }}>
                <Grid container spacing={2}>    
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                        <TextField
                            // id="input-with-icon-textfield"
                            label={t("activity_log.search_by_user_name")}                        
                            onChange={handleSearchChange}
                            value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                        />                   
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t("activity_log.screen_name")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="select_screen"
                                value={screenName}
                                label={t("activity_log.screen_name")}
                                MenuProps={{
                                    style: { maxHeight: 250,},
                                }}
                                onChange={handleScreenNameChange}>
                                {screenNameFilterOptions?.sort()
                                    .map((item:any,index:any)=>{
                                        return(
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        );
                                    })}                               
                            </Select>
                        </FormControl>    
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja" dateFormats={{ monthAndYear: "YYYY年MM月" }}>
                            {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePicker 
                                        className="from-date-seller-picker input"
                                        label={t("pr.from")} 
                                        value={valueFrom}
                                        format="YYYY/MM/DD"
                                        onChange={handleDateFrom}
                                        autoFocus={true}
                                        disableFuture
                                        // maxDate={valueTo}
                                        shouldDisableDate={shouldDisableDateFrom}                                                   
                                        sx={{ backgroundColor: "white", width:"100%"}} />
                                    {validator.current.message("valueFrom", valueFrom, [
                                        "required",
                                    ])}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePicker
                                        className="to-date-seller-picker input"
                                        label={t("pr.to")}
                                        format="YYYY/MM/DD"
                                        value={valueTo}
                                        onChange={handleDateTo}
                                        autoFocus={true}
                                        disableFuture
                                        // minDate={valueFrom}
                                        shouldDisableDate={shouldDisableDateTo}                                                   
                                        sx={{ backgroundColor: "white", width:"100%"}} />
                                    
                                    {validator.current.message("valueTo", valueTo, [
                                        "required",
                                    ])}
                                </Grid>
                            </Grid>
                            {/* </DemoContainer> */}
                        </LocalizationProvider>
                    </Grid>
                          
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={2} sx={{mt:1}}>
                        <Button 
                            variant="contained"
                            className="submit_btn"
                            sx={{textTransform: "capitalize"}}
                            onClick={handleSubmit}>
                            {t("pr.submit")}
                                
                        </Button>
                        <Button
                            variant="contained"    
                            className="submit_btn"                         
                            sx={{textTransform: "capitalize", ml:2}}
                            onClick={resetAllFilter}> {t("pr.reset")}
                        </Button>
                    </Grid>
            
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{position: "relative"}}>
                        <Button
                            variant="contained"
                            sx={{textTransform: "capitalize", float:"right", textAlign:"right"}}
                            disabled={loading || activityLogs?.length==0}
                            onClick={downloadActivityLog}
                        >
                            {t("pr.download")}
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Button>
                      
                    </Grid>
                </Grid>
               
            </Container>       
        

            {skeleton ? (
                <Skeleton
                    varient="rectangular"
                    width={0}
                    height={60}
                    count="10"
                    my={0}
                />
            ) : (
                <>
                    <Container className="minimum_column first_table_cell_small_width">
                        <MUIDataTable
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                selectableRowsHideCheckboxes: true,
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    viewColumns: {
                                        title: t("table_text.show_columns").toString(),
                                        titleAria: "Show/Hide Table Columns",
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",
                            }}
                        />
                    </Container>
                </>
            )}
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={activityLogList}
                totalPage={totalPages}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
        </>
    );
};

export default ActivityLog;
