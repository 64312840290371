import React, { useEffect, useState } from "react";
import {Box, Button, Container, Grid, } from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagination from "../common/Pagination";
import CONSTANTS, { LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import Skeleton from "../common/Skeleton";
import MUIDataTable from "mui-datatables";
import { useAppSelector } from "../../redux/hooks";
import StuckedTransctionService from "./service/StuckedTransctionService";
import PageTitle from "../common/PageTitle";
import { stuckedTransactionConfig } from "./config/stuckedTransactionConfig";


const StuckedTransction = (props: any) => {
    const { handleLoader, showSnackbar } = props;
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit );
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [stuckedTransactionData, setStuckedTransactionData] = useState <any>([]);
    const userType = useAppSelector((state) => state.common.loggedInUserType);

    const apiData: any[][] = [];
    const { t, i18n } = useTranslation();
    const [, forceUpdate] = useState(0);


    useEffect(()=>{
        stuckedTransaction(firstPage, limit);
    },[]);

    const stuckedTransaction = (currentPage: number, limit: number) => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new StuckedTransctionService(payload, isHitDummyURL)
            .stuckedTransctionList(currentPage,limit)
            .then((res:any) => {
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setStuckedTransactionData(res?.stuckedTransctionLogs);
                setSkeleton(false);
            })
            .catch((err:any) => {
                showSnackbar(err?.message, false);
            });
    };

    const deleteStuckedTransction = (id:any,storeId: any) => {
        handleLoader(true);
        const payload = {
            id:id,
            storeId : storeId
        };
        const isHitDummyURL = false;
        new StuckedTransctionService(payload, isHitDummyURL)
            .stuckedTransction(id,storeId)
            .then((res) => {
                setCurrentPage(currentPage);
                showSnackbar(res, true);
                stuckedTransaction(currentPage, limit);
                handleLoader(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };



    const columns = [
        {
            name: t("stucked_transaction.update_data_time"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.type"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.file_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.store_code"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.store_name"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.batch_id"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("stucked_transaction.action"),
            options: {
                filter: true,
                sort: false,
            },
        },
    ];

    const actionButton = (
        id: any,
        storeId: any
    ) => {
        return (
            <>
                <Box className="table_btn">
                    <Button onClick={()=>deleteStuckedTransction(id, storeId)}
                        className="table_btn"
                        variant="contained">
                        {t("stock_page.delete")}
                    </Button>
                </Box>
            </>
        );
    };
    
    const apiTableData = () => {
        {
            stuckedTransactionData?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    item?.fileUploadedDateTime,
                    item?.uploadType == stuckedTransactionConfig.STUCKED_TRANSACTION_PRODUCT_UPLOAD_TYPE ? t("stucked_transaction.product") : item?.uploadType == stuckedTransactionConfig.STUCKED_TRANSACTION_IMAGE_UPLOAD_TYPE ? t("stucked_transaction.image") : t("stucked_transaction.inventory"),
                    item?.fileName,
                    item?.storeCode,
                    item?.storeName,
                    item?.batchId,
                    item?.fileStatus == stuckedTransactionConfig.STUCKED_TRANSACTION_FILE_STATUS ?t("stucked_transaction.inprogress") :t("stucked_transaction.inprogress"),
                    actionButton(item.id ,item?.storeId)
                );
            });
        }
    };
    apiTableData();

    return (

        <>
            <div className="main">
                <Container>
                    <Grid container spacing={0} sx={{ pt: 3 }}>
                        <Grid item xs={12} sm={8} md={6} lg={6} sx={{ pt: 2 }}>
                            <PageTitle title={t("stucked_transaction.page_title")}/>
                        </Grid>
                    </Grid>
                </Container>

                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0} />
                ) : (

                    <Container sx={{ my: 4 }} className="minimum_column no_data_text_align_center sales_history_first_table_cell_small_width ">
                        <MUIDataTable
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                viewColumns: false,
                                search: false,
                                selectableRowsHideCheckboxes: true,
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    viewColumns: {
                                        title: t("table_text.show_columns").toString(),
                                        titleAria: "Show/Hide Table Columns",
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",
                            }} />
                    </Container>
                )}
            </div><Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={stuckedTransaction}
                totalPage={totalPages}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit} />
        </>

       
    );
};

export default StuckedTransction;
