import React, { useEffect, useState } from "react";
import { Box, Checkbox, Container, TextField, Typography, Grid, Button, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import RoleManagementService from "../services/roleManagementService";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import Skeleton from "../../common/Skeleton";


const SellerPermission = (props: any) => {

    const { showSnackbar, setPayloadData, payloadData, storeData, setAccessToAllSeller, accessToAllSeller,roleNameId , skeleton, adminData } = props;
    // const firstPage = 1;
    const arr : any[] = [];
    const[t] = useTranslation();

    const [sellerPermission, setSellerPermission] = useState<any>([]);    
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const [checkedItems, setCheckedItems] = useState<any>([]);
    const [allStoreChecked, setAllStoreChecked]=useState<boolean>(true);
    const [allSellerPermissionData,setAllSellerPermissionData]=useState<any>(null);
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        setAllStoreChecked(accessToAllSeller ? true : false);
    }, [accessToAllSeller]);

    useEffect(()=>{
        allSellerPermissionInfoApiCall(roleNameId);
    },[]);

    useEffect(() => {
        if(storeData.length){
            setCheckedItems(new Array(storeData?.length).fill(false));
        }
    }, [storeData]);

    useEffect(()=>{
        const selectedStoreList= sellerPermission?.map((item:any)=>{
            return (
                item?.id.toString()
            );
        });
        setPayloadData((pre:any)=>{
            const payload = {...pre};
            payload.sellers = selectedStoreList ?? [];
            payload.accessToAllSeller = allStoreChecked  ? 1 : 0;
            return payload;
        });

    },[checkedItems , allStoreChecked , accessToAllSeller,sellerPermission]);

    /**
 *
 * @param search - requesting search for managing store List
 * @returns - returns API response for Seller Permissions table in manage user role
 * @description - function to request store List get API 
 */

    const allSellerPermissionInfoApiCall = ( roleNameId:any) => { 
        if(roleNameId){
            const payload = {};
            const isHitDummyURL = false;
            new RoleManagementService(payload, isHitDummyURL)
                .allSellerPermissionList(roleNameId)
                .then((res:any) => {    
                    setSellerPermission(res);
                })
                .catch((err: any) => {
                    showSnackbar(err?.message, false);
                });
        } 
    };

    /**
 *
 * @param search - requesting search for managing store List
 * @returns - returns API response for Seller Permissions table in manage user role
 * @description - function to request store List get API 
 */

    const allSellerSearchInfoApiCall = (search:any) => {    
        const payload = {};
        const isHitDummyURL = false;
        new RoleManagementService(payload, isHitDummyURL)
            .allSellerSearchList(search)
            .then((res:any) => { 
                setAllSellerPermissionData(res);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };

    const apiData: any[][] = [];

    const storeNameArr = allSellerPermissionData?.map((item:any)=>{
        const storeNameValue=`${item?.storeName}(${item?.storeCode})`;
        return storeNameValue;
    });
    const columns = [
        {
            name: "Checkbox",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: () => {
                    return (
                        checkedAllCheckbox()
                    );
                },     
            },
        },
        {
            name: t("role_management_system.s_no"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.store_code"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.corporate_name"),
            options: {
                filter: false,
                sort: false,
            },
        },   
        {
            name: t("role_management_system.store_name"),
            options: {
                filter: false,
                sort: false,
            },
        }, 
    ];


    /**
 *
 * @param index - index of checkbox which is edit
 * @description - function to manage checkedItem state when any checkbox is edit
 */
    const handleCheckbox = (index:any) => {
        const checkbox = [...checkedItems];
        checkbox[index] =  !checkbox[index];
        setCheckedItems(checkbox);      
    };

    /**
 *
 * @description - function to manage checkedItem state when allStore Checkbox is clicked 
 */
    const handleAllCheckbox = () =>{
        if(checkedItems.filter((item: any)=> item == false).length == 0){
            const checkedArray =  new Array(checkedItems?.length).fill(false);
            setCheckedItems(checkedArray);
        }else{
            const checkedArray =  new Array(checkedItems?.length).fill(true);
            setCheckedItems(checkedArray);
        }
    };

    /**
 *
 * @param indexItem - index of checkbox which is edit
 * @description - function to show checkbox in table
 */
    const checkedCheckbox = (indexItem:any, id:any) =>{
        return(
            <>
                <Checkbox
                    checked={checkedItems[indexItem]? true : false }
                    onChange={()=>handleCheckbox(indexItem)}
                />
            </>
        );
    };

    const checkedAllCheckbox = () =>{
        return(
            <>
                <Checkbox
                    checked={Boolean(checkedItems.length && checkedItems.filter((item: any)=> item == false).length == 0)}
                    onChange={()=>handleAllCheckbox()}
                />
            </>
        );
    };
   
    const apiTableData = () => {
        {sellerPermission?.map((item: any, indexItem: number) => {
            arr.push(item?.id.toString());
            apiData[indexItem] = [];
            apiData[indexItem].push(
                checkedCheckbox(indexItem,item?.id),
                indexItem+1,
                item?.storeCode,
                item?.corporateName,
                item?.storeName,
            );
        });
        }
    };
    apiTableData();

    /**
 *
 * @description - function to handle add store functionality
 */
    const handleAddBtn =() => {
        const selectedSellerPermission = allSellerPermissionData.filter((item:any)=>{
            const storeCodevalue =selectedValue.split("(")[1].slice(0,-1);
            return (
                item?.storeCode==storeCodevalue
            );
        });
        if(roleNameId === 0){
            adminData.forEach((item : any)=>{
                if (item.key ==="product_management" && !allStoreChecked) {
                    item.permissions.view = 0;
                    item.permissions.download = 0;
                    item.permissions.execute = 0;
                }
            });
        }
        const isDuplicate =sellerPermission?.some((value: any) => {
            return(value?.storeCode==selectedSellerPermission[0]?.storeCode);
            
        });

        if(isDuplicate){
            setPayloadData(() => {
                const newItems = { ...payloadData };        
                if(newItems && newItems.modules){
                    newItems.modules.forEach((item : any) => {
                        if (item.key ==="product_management" && !allStoreChecked) {
                            item.permissions.view = 0;
                            item.permissions.download = 0;
                            item.permissions.execute = 0;
                        }
                    });
                }
                return newItems;
            });
            
            showSnackbar(t("role_management_system.duplicate_store_cannot_be_add"),false);
        }else{
            setSellerPermission(()=>{
                if(sellerPermission){
                    const newSellerPermission =[...sellerPermission];
                    newSellerPermission.push(selectedSellerPermission[0]);
                    return newSellerPermission;
                }else{
                    const newSellerPermission =[];
                    newSellerPermission.push(selectedSellerPermission[0]);
                    return newSellerPermission;
                }
            });
            setCheckedItems((prevItem:any)=>{
                const newCheckedItems=[...prevItem];
                newCheckedItems.push(false);
                return newCheckedItems;
            }); 
            setPayloadData(() => {
                const newItems = { ...payloadData };
                const sellerIdAsString = selectedSellerPermission[0]?.id.toString();
                newItems.sellers.push(sellerIdAsString);
                if(newItems && newItems.modules){
                    newItems.modules.forEach((item : any) => {
                        if (item.key ==="product_management" && !allStoreChecked) {
                            item.permissions.view = 0;
                            item.permissions.download = 0;
                            item.permissions.execute = 0;
                        }
                    });
                }
                return newItems;
            });
        }    
    };

    /**
 *
 * @description - function to handle delete store functionality
 */
    const handleDelete = () => {
        const newSellerPermission = sellerPermission.filter((item:any,index:any)=>{
            return !checkedItems[index];
        }) ;
        setSellerPermission(newSellerPermission);
        const selectedStoreList = newSellerPermission.map((item:any)=>{
            return item?.id;
        });
        setPayloadData((prevItems:any) => {
            const newItems = {...prevItems};
            const sellerIds = selectedStoreList.map((item:any)=> item.toString());
            newItems.sellers =[...sellerIds];
            if(newItems && newItems.modules){
                newItems.modules.forEach((item : any) => {
                    if (item.key ==="product_management" && !allStoreChecked) {
                        item.permissions.view = 0;
                        item.permissions.download = 0;
                        item.permissions.execute = 0;
                    }
                });
            }
            return newItems;
        });
        const checkedArray =  new Array(newSellerPermission?.length).fill(false);
        setCheckedItems(checkedArray);
    };

    /**
 *
 * @description - function to handle all Store Checkbox
 */
    const handleAllStoreCheckbox = () =>  {
        setAllStoreChecked((prev) => !prev);
        setAccessToAllSeller((prev:any) => !prev);
        setSellerPermission([]);
    };

    const onsearchApicall = () =>{
        if(search){
            allSellerSearchInfoApiCall(search);
        }
    };
    
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    const handleSearchChange = (e: any) => {       
        setSearch(e.target.value);     
    };

    const disableDeleteBtn =checkedItems?.some((value: any) => value);

    return (

        <>                      
            <Box className="AdministrativeSiteBox">
                <Box className="administrativeSiteTitle SellerPermissionTitle">
                    <Grid container>
                        <Grid item xs={12} sm={3} md={3} lg={3} className="sellerPermissionBox">
                            <Typography className="sellerPermissionTitle" >{t("role_management_system.seller_permissions")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={9} >
                            <Box className="seller_permission_header_box">  
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    className="auto_complete"
                                    options={storeNameArr || []}
                                    onChange={(event, newValue) => setSelectedValue(newValue)}
                                    sx={{ width: 300 }}
                                    filterOptions={(x) => x}
                                    disabled={allStoreChecked}
                                    noOptionsText="データが見つかりません"
                                    renderInput={(params:any) => 
                                        <TextField
                                            {...params}
                                            id="input-with-icon-textfield"
                                            label={t("role_management_system.search_by_store_name")}
                                            disabled={ allStoreChecked}
                                            onChange={(e)=>handleSearchChange(e)}
                                        />
                                    }
                                /> 
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className="seller_permission_icon"
                                    disabled={!selectedValue || allStoreChecked}
                                    onClick={handleAddBtn}
                                >
                                    <AddIcon />
                                </Button>
                                <Button                                    
                                    fullWidth
                                    variant="contained"
                                    className="seller_permission_icon"
                                    disabled={!disableDeleteBtn || allStoreChecked}
                                    onClick={handleDelete}
                                >
                                    <DeleteOutlineIcon />
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className={`${allStoreChecked ? "seller_permission_icon_selected":"seller_permission_icon"}`}
                                    // disabled={true}
                                    onClick={handleAllStoreCheckbox}
                                >
                                    <SelectAllIcon/>
                                </Button>  

                            </Box>
                                
                        </Grid>
                    </Grid>
                </Box>                                         
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="4"
                        my={0}
                    />
                ) : (
                    <Container className="common_table createUserRoleTable">
                        {allStoreChecked ? 
                            <Typography className="all_store">{t("role_management_system.all_store_selected")}</Typography>
                            : 
                            (
                                <Box className="scrollbar" >
                                    <MUIDataTable 
                                        title={""}
                                        columns={columns}
                                        data={apiData}
                                        options={{
                                            pagination: false,
                                            print: false,
                                            download: false,
                                            filter: false,
                                            search: false,
                                            viewColumns:false,
                                            selectableRowsHideCheckboxes: true,                              
                                            textLabels: {
                                                body: {
                                                    noMatch: t("table_text.no_match").toString(),
                                                },
                                                viewColumns: {
                                                    title: t("table_text.show_columns").toString(),
                                                    titleAria: "Show/Hide Table Columns",
                                                },
                                                toolbar: {
                                                    search: t("table_text.search").toString(),
                                                    viewColumns: t("table_text.view_columns").toString(),
                                                },
                                            },
                                            searchPlaceholder: t("table_text.search_placeholder").toString(),
                                            responsive: "standard",                                       
                                        }}                                  
                                    />
                                </Box>
                            )

                        }
                   
                    </Container>  
                ) }                          
            </Box>                                                 
        </>
    );
};

export default SellerPermission;