import React, { useEffect, useRef, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Popover,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../constants/constants";
import PasswordService from "../service/PasswordService";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";

const CreatePassword = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    window.onpopstate = function (e: any) {
        if (window.location.href.includes(ROUTES.VERIFY_OTP)) {
            const responseMessage = t("create_password.are_you_sure_want_to_exit");
            const response = confirm(responseMessage);
            if (!response) {
                e.stopPropagation();
                window.history.forward();
            } else {
                navigate(ROUTES.INITIAL_PAGE);
            }
        }
    };

    {
    /* Language translate function*/
    }
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    {
    /* Language translate function*/
    }

    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [password, setPassword] = useState("");
    const location = useLocation();
    const [email, setEmail] = useState(location?.state?.email);
    useEffect(() => {
        !email ? navigate(ROUTES.INITIAL_PAGE) : null;
    });
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState({
        passwordError: "",
        confirmPasswordError: "",
    });
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successAlert, setSuccessAlert] = useState(false);

    // validation
    const [, forceUpdate] = useState(0);


    const passwordRestrictionWord = CONSTANTS.RESTRICTED_KEYWORD;
    const isMatchedPasswordRestrictionWord = (password: string) => {
        const isMatched = passwordRestrictionWord.find((item) => {
            if (password.toLowerCase().includes(item.toLowerCase())) {
                return true;
            }
        });
        if (isMatched) {
            return isMatched;
        }
        return false;
    };
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                in: t("create_password.password_does_not_match"),
                regex: t("create_password.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                cainz: {
                    message: t("required.the_password_can_not_contain"),
                    rule: (val: string) => {
                        return !isMatchedPasswordRestrictionWord(val);
                    },
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    //validation

    const handleOnBlur = (e: any) => {
        const { name } = e.target;

        validator.current.showMessageFor(name);
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        name === "password" ? setPassword(value) : setConfirmPassword(value);

        validator.current.hideMessageFor(name);
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { newPassword: password, confirmPassword, email: email };
            new PasswordService(payload)
                .reset()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    navigate(ROUTES.INITIAL_PAGE);
                })
                .catch((err) => {
                    handleLoader(false);
                    // const { success, message } = err.response.data;
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    const passSuggestion = (e: any) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickShowPassword = () => {
        passwordType == "password"
            ? setPasswordType("text")
            : setPasswordType("password");
    };

    const handleMouseDownPassword = () => {
        setPasswordType("text");
    };

    const handleClickShowConfirmPassword = () => {
        confirmPasswordType == "password"
            ? setConfirmPasswordType("text")
            : setConfirmPasswordType("password");
    };

    const handleMouseDownConfirmPassword = () => {
        setConfirmPasswordType("text");
    };

    const open = Boolean(anchorEl);

    return (
        <>
            {/* Login page starts here */}
            <div className="main">
                <Grid>
                    <Container>
                        <Grid container spacing={4}>
                            {/* offset grid with column*/}
                            <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                            {/* main grid column! */}
                            <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                                <img className="logo-img" alt="cainz"src={imagePath("logo-large.png")}  />
                                <Box className="logo-content">
                                    <Grid item xs={12} md={12}>
                                        <Grid sx={{ mb: 2 }}>
                                            <TextField
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                // onTouchStart={handleMouseDownConfirmPassword}
                                                                // onTouchEnd={handleClickShowConfirmPassword}
                                                                onClick={handleClickShowPassword}
                                                                // onMouseDown={handleMouseDownConfirmPassword}
                                                            >
                                                                { passwordType=="password" ?
                                                                    <VisibilityIcon /> :
                                                                    <VisibilityOffIcon />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type={passwordType}
                                                fullWidth
                                                name="password"
                                                value={password}
                                                label={t("create_password.new_password")}
                                                variant="outlined"
                                                onChange={(e) => handleChange(e)}
                                                error={error.passwordError ? true : false}
                                                helperText={error.passwordError}
                                                onBlur={(e) => handleOnBlur(e)}
                                            />
                                            {validator.current.message("password", password, [
                                                "required",
                                                CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                                { regex: CONSTANTS.PASSWORD_REGEX },
                                            ])}
                                        </Grid>
                                        <Grid sx={{ mb: 2 }}>
                                            <TextField
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                // onTouchStart={handleMouseDownConfirmPassword}
                                                                // onTouchEnd={handleClickShowConfirmPassword}
                                                                onClick={handleClickShowConfirmPassword}
                                                                // onMouseDown={handleMouseDownConfirmPassword}
                                                            >
                                                                {confirmPasswordType == "password" ? (
                                                                    <VisibilityIcon />
                                                                ) : (
                                                                    <VisibilityOffIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type={confirmPasswordType}
                                                fullWidth
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                label={t("create_password.confirm_password")}
                                                variant="outlined"
                                                onChange={(e) => handleChange(e)}
                                                error={error.confirmPasswordError ? true : false}
                                                helperText={error.confirmPasswordError}
                                                onBlur={(e) => handleOnBlur(e)}
                                            />
                                            {validator.current.message(
                                                "confirmPassword",
                                                confirmPassword,
                                                [
                                                    "required",
                                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                                    { in: password },
                                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                                ]
                                            )}
                                        </Grid>

                                        <Typography sx={{my:1, width: "auto", color:"#201d1d94"}}>
                                            {t("create_password.password_suggestion")}
                                        </Typography>

                                        <Grid sx={{ mb: 2 }}>
                                            <Button fullWidth onClick={handleSubmit}>
                                                {t("update_password.submit")}
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>

                            {/* offset grid with column*/}
                            <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        </Grid>
                    </Container>
                </Grid>
                {/* Login page ends here */}
            </div>
        </>
    );
};

export default CreatePassword;
