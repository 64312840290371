import React, { useEffect, useState } from "react";
import {  Box, Checkbox, Container, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import Skeleton from "../../common/Skeleton";
import CONSTANTS from "../../../constants/constants";


const SellerSite = (props: any) => {

    const { sellerSite ,setSellerRoleData,showSnackbar ,skeleton} = props;

    const[t,i18n] = useTranslation();
    const [sellerFinalData, setSellerFinalData]= useState<any>([]);  

    const apiData: any[][] = [];
    
    /**
 *
 * @description - function to make sellerSite Site JSON
 */

    useEffect(() => {
        let moduleData : {moduleId: string, key: string, name: string, panel: string, permissions: any};
        const initialState = () => {
            const mappedPermissions = sellerSite?.map((item :any) => {
                const permissions = item?.permissions;

                // For storeManagement 
                if(item.key == "store_detail_management"){
                    permissions.view = 1;
                }
                moduleData = {
                    moduleId:item?.moduleId,
                    key: item?.key,
                    ... (i18n.language == "jp" ? { name: item?.nameJPN } : { name: item?.name }),
                    panel:item?.panel,
                    permissions: permissions,
                };
                return moduleData;
            });
            setSellerFinalData(mappedPermissions);
        };
        initialState();
    }, [sellerSite,i18n.language]);

    /**
 *
 * @description - function to manage Role state when any checkbox is edit
 */
    useEffect(() => {
        const changeUserRole = () =>{
            setSellerRoleData((prevItems:any) => {
                const newItems = {...prevItems,seller:sellerFinalData};
                return newItems;
            });
        };
        changeUserRole();
    }, [sellerFinalData]);

    const columns = [
        {
            name: t("role_management_system.particulars"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.reference_authority"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.download_permission"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("role_management_system.execute_permission"),
            options: {
                filter: false,
                sort: false,
            },
        },     
    ];

    /**
 *
 * @param index - index of checkbox which is edit
 * @param field - field corresponding to that checkbox
 * @description - function to manage checkedItem state when any checkbox is edit
 */
    const handleCheckboxChange = (index:any,value:any, field:any) => {
        if( field == "execute_permission"){
            setSellerFinalData((prevItems: any) => {
                const newItems = [...prevItems];
                newItems[index].permissions.execute = newItems[index].permissions.execute ^ 1;
                newItems[index].permissions.execute ? 
                    ( newItems[index].permissions.view =1,
                    newItems[index].permissions.download =1)
                    :
                    "";
                return newItems;
            });
        }else if(field == "download_permission"){
            setSellerFinalData((prevItems:any) => {
                const newItems = [...prevItems];
                newItems[index].permissions.download = newItems[index].permissions.download ^ 1;
                newItems[index].permissions.download ? 
                    (newItems[index].permissions.view =1,
                    newItems[index].permissions.execute=0)
                    :
                    (newItems[index].permissions.execute =0,
                    newItems[index].permissions.download=0)
                ;
                return newItems;
            });
        }else{
            index == 5 ? (
                setSellerFinalData((prevItems:any) => {
                    const newItems = [...prevItems];
                    newItems[index].permissions.view = CONSTANTS.RMS_PERMISSION_VIEW;
                    return newItems;
                })
            ) : (
                setSellerFinalData((prevItems:any) => {
                    const newItems = [...prevItems];
                    newItems[index].permissions.view = newItems[index].permissions.view ^ 1;
                    newItems[index].permissions.view ? 
                        ""          
                        :
                        (newItems[index].permissions.execute =0,
                        newItems[index].permissions.download=0) 
                    ;
                    return newItems;
                })
            );
        }      

    };

    /**
 *
 * @param index - index of checkbox which is edit
 * @description - function to show checkbox in table under refrence autority
 */
    const refrenceAuthorityCheckbox = (value:any ,index:any) =>{
        return(
            <>
                <Checkbox
                    checked={value}
                    onChange={() => handleCheckboxChange(index,value, "reference_authority")}
                />
            </>
        );
    };

    /**
 *
 * @param index - index of checkbox which is edit
 * @description - function to show checkbox in table under download permission
 */
    const downloadPermissionCheckbox = (value:any ,index:any) =>{
        return(
            <>
                <Checkbox
                    checked={value}
                    onChange={() => handleCheckboxChange(index,value, "download_permission")}
                />
            </>
        );
    };

    /**
 *
 * @param index - index of checkbox which is edit
 * @description - function to show checkbox in table under execute permission
 */
    const uploadPermissionCheckbox = (value:any ,index:any) =>{
        return(
            <>
                <Checkbox
                    checked={value}
                    onChange={() => handleCheckboxChange(index,value, "execute_permission")}
                />
            </>
        );
    };

    const apiTableData = () => {
        {sellerFinalData?.map((item: any, indexItem: any) => {
            apiData[indexItem] = [];
            apiData[indexItem].push(                    
                item?.name,
                refrenceAuthorityCheckbox((item?.permissions.view) == 1 ? true : false, indexItem),
                downloadPermissionCheckbox((item?.permissions.download) == 1 ? true : false, indexItem),
                uploadPermissionCheckbox((item?.permissions.execute) == 1 ? true : false, indexItem)
            );
        });
        }
    };
    apiTableData();

    return (

        <>                      
            <Box className="AdministrativeSiteBox">
                <Typography className="administrativeSiteTitle">{t("role_management_system.seller_site")}</Typography>
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="6"
                        my={0}
                    />
                ) : (
                    <Container className="common_table createUserRoleTable">
                        <MUIDataTable 
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                viewColumns:false,
                                selectableRowsHideCheckboxes: true,                              
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    viewColumns: {
                                        title: t("table_text.show_columns").toString(),
                                        titleAria: "Show/Hide Table Columns",
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",                                       
                            }}                                  
                        />
                    </Container> 
                )}                         
            </Box>                                                 
        </>
    );
};

export default SellerSite;