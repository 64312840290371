import React, { useState, useRef } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    CircularProgress
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import Excel from "exceljs";
import CONSTANTS, { SHEET_NAME } from "../../../constants/constants";
import PageTitle from "../../common/PageTitle";
import PayeeTableService from "../service/payeeInfo";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import PaymentTableService from "../service/paymentTable";
import Utility from "../../../utils/Utility";
import ENC_DEC_CONFIG from "../config/config";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FileUpload = (props: any) => {
    const { handleLoader, showSnackbar, selectDataTable, setPaymentTable, 
        setPayeeInfoTableComp, setUploadFileComp,setSelectAction, setSelectDataTable} = props;
    const dispatch = useAppDispatch();
    const utility = new Utility;
    const [, forceUpdate] = useState(0);
    const { t, i18n } = useTranslation();
    
    const [file, setFile] = useState<any>("");
    const [fileName, setFileName] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [resetDialogBox,setResetDialogBox] =useState(false);
    const [skeleton, setSkeleton] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const allowFiles = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
    ];

    const validator2 = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("Maximum 40 Character"),
                required: t("required.please_choose_file"),
            },
        })
    );
   

    /**
     * @description uploadedFile function use for upload file. 
     */ 

    const uploadedFile = () =>{
        setResetDialogBox(false);
        isFileUpload ? alert(t("encryption_decryption.file_can_not_be_uploaded")) : FileuploadData();
    };

    const workSheetExcel = ()=>{
        setIsFileUpload(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setFile("");
        setFileName(""); 
        handleLoader(false);
    };

    /**
     * FileuploadData contains uploaded excel file and file name. 
     * @returns - all uploaded data
     * @description - FileuploadData function compare Payment_table and orderTable
     * header - stores in constant file 
     * store data - stores in local storage 
     * show data conditionaly 
     */ 
   
    const FileuploadData = () => {  
        setIsFileUpload(true);
        setFormSubmit(true);
        handleLoader(true);
        if (file) {
            dispatch(commonActions.SAVE_FILE_NAME(fileName?.split("\\")[fileName?.split("\\").length-1]));
            dispatch(commonActions.PROGRESSBAR_MESSAGE(0));            
            const workbook = new Excel.Workbook();
            workbook.xlsx.load(file)
                .then(function() {
                    const worksheet = workbook?.worksheets[0];
                    const columnCount = worksheet?.columnCount;
                    const rowCount = worksheet?.actualRowCount;
                    const row = worksheet?.getRow(1).values;
                    const rowItem=Object.values(row);
                    const dataArray:any=[];
                    worksheet.eachRow({ includeEmpty: true }, function (row) {
                        dataArray.push(row.values);
                    });
                    const keys = dataArray[0]; 
                    const result = dataArray.slice(1).map((item:any, rowNumber: number) => {
                        
                        const obj: { [key: string]: any } = {};                   
                        keys.forEach((key:any, index:any) => {
                            if(key=="cancellation_datetime"){
                                obj["cancellationDateTime"]= item[index];
                            }else{
                                obj[utility.camelCase(key)] = item[index]; 
                            }
                            // if(item[index]==undefined){
                            //     console.log(key);
                            // }
                            obj["isUpload"]= true;
                        });
                        return obj;
                    });

                    const column = selectDataTable == "Payment_table"? ENC_DEC_CONFIG.PAYMENT_TABLE_COLUMN: ENC_DEC_CONFIG.ORDER_TABLE_COLUMN;
                    handleLoader(false);
                    if(rowCount>ENC_DEC_CONFIG.MAX_ROW_COUNT){
                        showSnackbar(t("encryption_decryption.maximum_row_limit"));
                        workSheetExcel();
                    }else if(rowCount == 1){
                        showSnackbar(t("encryption_decryption.no_data_found_in_excel"));
                        workSheetExcel();
                    }else if(columnCount < column){
                        showSnackbar(t("encryption_decryption.invalid_file_template"));
                        workSheetExcel();
                    }
                    else{
                        const staticHeader = selectDataTable == "Payment_table"? CONSTANTS.PAYMENT_SHEET_HEADER: CONSTANTS.ORDER_SHEET_HEADER;
                        let match = true;
                        staticHeader.map((item:any)=>{
                            const newRowItems = rowItem.filter((current:any)=>{
                                return current==item;
                            });
                            if(newRowItems.length !== 1){
                                match=false;
                                showSnackbar(t("encryption_decryption.invalid_file_template"));
                                workSheetExcel();  
                            }
                        });
                        if(match){
                            const selectStorageTable = selectDataTable == "Payment_table"? "paymentTable": "orderTable";
                            localStorage.setItem(selectStorageTable, JSON.stringify(result));
                            if(selectDataTable=="Payment_table"){
                                setPaymentTable(true);
                            }else{
                                setPayeeInfoTableComp(true);
                            }
                            setUploadFileComp(false);
                        }
                    }                 
                });

        } else {
            setIsFileUpload(false);
            handleLoader(false);
            validator2.current.showMessages();           
        }
    };

    /** 
     * @description -file uploaded validation and conditions here
     */ 

    const handleOnChange = (e:any) => {
        if (e.target.files.length != 0) {
            if (e.target.files[0].size < ENC_DEC_CONFIG.FILE_SIZE) {
                if (allowFiles.includes(e.target.files[0].type)) {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                    setResetDialogBox(true);
                } else {
                    showSnackbar(t("encryption_decryption.invalid_file_type"), false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                showSnackbar(t("encryption_decryption.file_size_10_mb"), false);
                setFile("");
                setFileName("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };
    
    function clickFileButton(){
        const button=document.getElementById("file-upload-input");
        button?.click();
    }

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };
    
    const handleDrop = (e: any) => {
        e.preventDefault();
        if (e.dataTransfer.files.length != 0) {
            if (e.dataTransfer.files[0].size < ENC_DEC_CONFIG.FILE_SIZE) {
                if (allowFiles.includes(e.dataTransfer.files[0].type)) {
                    setFile(e.dataTransfer.files[0]);
                    setFileName(e.dataTransfer.files[0]?.name);
                    setResetDialogBox(true);
                } else {
                    showSnackbar(t("encryption_decryption.invalid_file_type"), false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                showSnackbar(t("encryption_decryption.file_size_10_mb"), false);
                setFileName("");
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        } 
    };

    /** 
     * @description - resetUploadFile function ResetDialogBox file name and empty file name  
     */ 
    const resetUploadFile = () =>{
        setFileName("");
        setFile("");
        setResetDialogBox(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };
    const replaceFileUpload = () =>{
        const button=document.getElementById("file-upload-input");
        button?.click();
    };

    /** 
     * @description - download data form local json in order table header
     *  user can fill and upload excel file. 
     */ 
    const OrderTableData = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = true;
        setSkeleton(true);
        new PayeeTableService(payload, isHitDummyURL)
            .downloadOrderHeader()
            .then((res:any) => {
                handleOnExports(res?.data);
                setSkeleton(false);
            })
            .catch((err:any) => {
                showSnackbar(err?.message, false);
            });
    };

    /** 
     * @description - download data form local json in payment table header
     *  user can fill and upload excel file. 
     */ 
    const downloadPaymentData = () =>{
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = true;
        setSkeleton(true);
        new PaymentTableService(payload, isHitDummyURL)
            .downloadPaymentTableHeader()
            .then((res:any) => {
                handleOnExport(res?.data);
                setSkeleton(false);
            })
            .catch((err:any) => {
                setSkeleton(false);
                showSnackbar(err?.message, false);
            });   
    };

    const downloadTemplate = () =>{
        if(selectDataTable == "Payment_table" ){
            downloadPaymentData();
        }else{
            OrderTableData();
        }
    };

    /**
     * @description backToPrevious function reset all data .
    */
    const backToPrevious = () =>{
        setPaymentTable(false);
        setPayeeInfoTableComp(false);
        setUploadFileComp(false);
        setSelectAction("");
        setSelectDataTable("");
        localStorage.removeItem("paymentTable");
        localStorage.removeItem("orderTable");
    };

    const handleOnExports = (downloadObject?: any) => {
        const downloadData: any =  [{}] ;
        Object.entries(downloadObject).forEach(([key, value]: [any, any]) => {
            downloadData[0][value] = null;
        });
        const fileName= SHEET_NAME.ORDER_DATA;
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.ORDER_DATA);
        setLoading(false);
    };


    const handleOnExport = (downloadObject?: any) => {
        const downloadData: any =  [{}] ;
        Object.entries(downloadObject).forEach(([key, value]: [any, any]) => {
            downloadData[0][value] = null;
        });
        const fileName=SHEET_NAME.PAYMENT_DATA;
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.PAYMENT_DATA);
        setLoading(false);
    };

    return (
        <>
            <div className="main">
                <PageTitle title={t("encryption_decryption.encryption_decryption_tools")} /><Container>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box className="btn_box">
                                <Box>
                                    <Button variant="contained" onClick={backToPrevious}> {t("encryption_decryption.back")}  </Button>
                                </Box>
                                               
                                <Box>
                                    <Button
                                        className="margin_download_button"
                                        variant="contained"
                                        disabled={loading || downloadDataToExcel.length == 0}
                                        sx={{ textTransform: "capitalize" }}
                                        onClick={downloadTemplate}
                                    >
                                        {t("order_table.template_download")}
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Box>
                                        
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Box className="file-border" onDragOver={handleDragOver}
                                onDrop={handleDrop}>
                                <Box onClick={() => { clickFileButton(); } } className="cursor-pointer">
                                    <Typography>
                                        <CloudUploadIcon
                                            className="primary download-upload-icon" />
                                    </Typography>
                                    <p onChange={(e) => handleOnChange(e)}>
                                        {fileName ? fileName : ""}

                                        <input
                                            id="file-upload-input"
                                            onChange={(e) => e.target.value}
                                            accept=".xlsx,.xls"
                                            className="test"
                                            ref={fileInputRef}
                                            type="file"
                                            hidden />
                                    </p>
                                    <Typography>{t("encryption_decryption.please_select_file")}</Typography>
                                    {!fileName && formSubmit ? validator2.current.message("store name", fileName, ["required"]) : null}
                                </Box>
                                {resetDialogBox ?
                                    <Box className="file_upload_btn_box">
                                        <Button onClick={replaceFileUpload} className="file_upload_replace_btn" variant="contained">
                                            {t("encryption_decryption.replace")}
                                        </Button>
                                        <Button onClick={resetUploadFile} className="file_upload_reset_btn" variant="contained">
                                            {t("encryption_decryption.reset")}
                                        </Button>
                                    </Box> : ""}
                            </Box>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid">
                                <Button onClick={uploadedFile} className="upload-btn" variant="contained">
                                    {t("encryption_decryption.upload")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default FileUpload;

