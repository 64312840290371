import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { RoleManagementWithPaginationType } from "../../../interfaces/RoleManagementInterface";
import SellerPermissionType from "../../../interfaces/SellerPermissionInterface";
import SellerPermissionListType from "../../../interfaces/SellerPermissionListInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class RoleManagementService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async roleManagementList(
        page: number,
        limit = LIST_LIMIT.STORE_LIST_LIMIT,
       
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.ROLE_MANAGEMENT_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ROLE_MANAGEMENT_LIST}?page=${page}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {         
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;
            const RoleManagementData: RoleManagementWithPaginationType = {
                roleMangementLogs: data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return RoleManagementData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async createUserRoleList(roleNameId:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CREATE_USER_ROLE_LIST}`,
                this.payload
            ).get()
            : roleNameId != 0 ?           
                await new AxiosUtils(
                    `${API.BASE_URL}${serverAPIPath.CREATE_USER_ROLE_LIST}?id=${roleNameId}`,
                    this.payload,
                    this.headers
                ).get() :
                await new AxiosUtils(
                    `${API.BASE_URL}${serverAPIPath.CREATE_USER_ROLE_LIST}`,
                    this.payload,
                    this.headers
                ).get()
            ;
        if (response?.data?.success) {         
            const { data } = response.data.data;
            // const TransactionsData: RoleManagementWithPaginationType = {
            //     roleMangementLogs: data,
            //     pagination: {
            //         totalPages,
            //         currentPage,                  
            //         currentTotalCount,
            //         totalCount,
            //     },
            // };
            return  response.data.data;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async sellerPermissionList(roleNameId:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SELLER_PERMISSION_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_PERMISSION_LIST}`+roleNameId,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {         
            const sellerPermission: SellerPermissionListType = response?.data?.data;
            return sellerPermission;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async allSellerPermissionList( roleId:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.All_SELLER_PERMISSION_LIST}`,
                this.payload
            ).get()
            : 
            await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.All_SELLER_PERMISSION_LIST}?roleId=${roleId}`,
                this.payload,
                this.headers
            ).get();
            
        if (response?.data?.success) {         
            const sellerPermission: SellerPermissionType = response?.data?.data;
            return sellerPermission;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async allSellerSearchList(search:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.All_SELLER_PERMISSION_LIST}`,
                this.payload
            ).get()
            : 
            await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.All_SELLER_PERMISSION_LIST}?search=${search}`,
                this.payload,
                this.headers
            ).get() ;
            
        if (response?.data?.success) {         
            const sellerPermission: SellerPermissionType = response?.data?.data;
            return sellerPermission;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async deleteUserRole(useRoleId: number){
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.DELETE_USER_ROLE}`,
                this.payload
            ).get()
            : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_USER_ROLE}`+useRoleId, this.payload, this.headers).delete();
        let responeMessage: string;

        if(response?.data?.success==true) {
            responeMessage = response?.data?.message;
            return responeMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);

        }
    }
    async saveUserRoleDetail(roleNameId:any){
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.USER_ROLE_DETAILS}`,
                this.payload
            ).get()
            : roleNameId  ?
                await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.USER_ROLE_DETAILS}`+roleNameId, this.payload, this.headers).put()
                :await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.USER_ROLE_DETAILS}`, this.payload, this.headers).post();
        let isUserRoleSave;
        if(response?.data?.success==true) {
            isUserRoleSave = response?.data;
            return isUserRoleSave;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }


}

export default RoleManagementService;